import React, { useContext, useState } from "react";
import { useForm, useFormState } from "react-hook-form";
import axios from "axios";
import Swal from "sweetalert2";
import { VARIABLES } from "../../Constants";
import { useNavigate } from "react-router-dom";
import { UsersContext } from "../../context/UsersContext/UsersContextProvider";

const Login = (props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm();
  const [passwordToggle, setPasswordToggle] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { setIsUserLoggedIn } = useContext(UsersContext);
  const { isValid } = useFormState({
    control,
  });

  const onSubmit = (data) => {
    const url = VARIABLES.url + "/api-app/login";
    let headers = { "Content-Type": "application/json" };
    let reqOptions = {
      url: url,
      method: "POST",
      headers: headers,
      mode: "no-cors",
      data: data,
    };

    setIsLoading(true);

    axios
      .request(reqOptions)
      .then(function (response) {
        const userData = response.data;
        const loginStatus = response.status;
        if (loginStatus === 200) {
          setIsLoading(false);
          navigate("/");
          props.onLogin(userData);
          setIsUserLoggedIn(true);
        } else if (loginStatus === 210) {
          setIsLoading(false);
          Swal.fire(userData.status, "Please check password", "error");
        } else if (loginStatus === 211) {
          setIsLoading(false);
          Swal.fire(userData.status, "Please check username", "error");
        }
      })
      .catch((error) => {
        console.log("Caught error");
        console.log(error.response);
        setTimeout(() => {
          setIsLoading(false);
          Swal.fire({
            title: "Error",
            text: "Insufficient information to logged in",
            icon: "error",
            confirmButtonText: "OK",
          });
        }, 5000);
      });
  };

  const changeSidebar = (section) => {
    props.setMenubar(section);
    navigate("/");
  };

  // useEffect(() => {
  //   if(isLoading === true) {
  //   setTimeout(() => {
  //     setIsLoading(false);
  //     alert("Insufficient information to logged in")
  //   }, 5000);
  // }
  // },[isLoading])

  return (
    <div id="mytask-layout" className="theme-indigo">
      <div className="main p-2 py-3 p-xl-5 ">
        <div className="body d-flex p-0 p-xl-5">
          <div className="container-xxl">
            <div className="row g-0">
              <div className="col-lg-6 d-none d-lg-flex justify-content-center align-items-center rounded-lg auth-h100">
                <div style={{ maxWidth: "25rem" }}>
                  <div className="text-center mb-5">
                    <img src="nipun-logo.png" alt="login-img" />
                  </div>
                  <div className="mb-5">
                    <h2 className="color-900 text-center">THE NIPUN</h2>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 d-flex justify-content-center align-items-center border-0 rounded-lg auth-h100">
                <div
                  className="w-100 p-3 p-md-5 card border-0 bg-dark text-light"
                  style={{ maxWidth: "32rem" }}
                >
                  <form
                    onSubmit={handleSubmit(onSubmit)}
                    className="row g-1 p-3 p-md-4"
                  >
                    <div className="col-12 text-center mb-1 mb-lg-5">
                      <h1>Sign In to Nipun</h1>
                      <span>Free access to our dashboard.</span>
                    </div>

                    <div className="col-12">
                      <div className="mb-2">
                        <label className="form-label">Username</label>
                        <input
                          type="text"
                          className="form-control form-control-lg"
                          placeholder="Username"
                          style={{
                            border: errors.username ? "3px solid red" : "",
                            borderRadius: "8px",
                          }}
                          {...register("username", { required: true })}
                          id="text-m-g-01-username"
                        />
                        {/* {errors.username && <span className='required_field'>Username is required</span>} */}
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="mb-2">
                        <div className="form-label">
                          <span className="d-flex justify-content-between align-items-center">
                            Password
                          </span>
                        </div>
                        <div
                          className="input-group"
                          style={{
                            border: errors.password ? "3px solid red" : "",
                            borderRadius: "8px",
                          }}
                        >
                          <input
                            type={`${
                              passwordToggle === true ? "text" : "password"
                            }`}
                            className="form-control form-control-lg"
                            placeholder="Password"
                            {...register("password", { required: true })}
                            id="text-m-g-02-password"
                          />
                          <span
                            className="input-group-text"
                            onClick={() => setPasswordToggle(!passwordToggle)}
                            id="basic-addon2"
                          >
                            <i
                              className={`${
                                passwordToggle === true
                                  ? "icofont-eye me-2 fs-6"
                                  : "icofont-eye-blocked me-2 fs-6"
                              }`}
                            ></i>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 text-center mt-4">
                      {isLoading === true ? (
                        <button className="btn text-white">Loading...</button>
                      ) : (
                        <button
                          type="submit"
                          className="btn btn-lg btn-block btn-light lift text-uppercase"
                          atl="signin"
                          id="button-m-g-03-signin"
                        >
                          SIGN IN
                        </button>
                      )}
                    </div>
                    <div className="col-12 text-center mt-4">
                      <span className="text-muted">
                        Want a Nipun Account?{" "}
                        <a href="javascript:void(0)" className="text-secondary">
                          Contact Us!
                        </a>
                      </span>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
