import React, { useContext } from "react";
import Task from "./Task";
import { Accordion } from "react-bootstrap";
import DroppableArea from "./DroppableArea";
import DragOverDetector from "./DragOverDetector";
import { ListContext } from "../ListContextProvider";

const Board = ({ tasks }) => {
  const { activeKey, toggleAccordions } = useContext(ListContext);
  return (
    <Accordion className="w-100" activeKey={activeKey} flush>
      {tasks.map((task, i) => (
        <div key={task.id}>
          <DroppableArea id={`${task.id}_${0}`} />
          <DragOverDetector id={task.id}>
            <Task key={task.id} task={task}>
              <Accordion.Item eventKey={task.id}>
                <Accordion.Header onClick={() => toggleAccordions(task?.id)}>
                  {task.name}
                </Accordion.Header>
                <Accordion.Body className="pe-0">
                  {task.tasks && task?.tasks?.length !== 0 ? (
                    <Board tasks={task.tasks} />
                  ) : (
                    <>
                      <span>No Subtasks</span>
                      <DroppableArea id={`${task.id}_${-1}`} />
                    </>
                  )}
                </Accordion.Body>
              </Accordion.Item>
            </Task>
          </DragOverDetector>
          {i === tasks.length - 1 ? (
            <DroppableArea id={`${task.id}_${1}`} />
          ) : (
            ""
          )}
        </div>
      ))}
    </Accordion>
  );
};

export default Board;
