import React from "react";
import {
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { CSS } from "@dnd-kit/utilities";

const List = ({ children, listItem }) => {
  const { setNodeRef, transform, transition, isDragging } = useSortable({
    id: listItem.id,
    data: {
      type: "list",
      item: listItem,
    },
  });
  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
  };
  return (
    <div className="ListContainerTest" style={style} ref={setNodeRef}>
      <div
        className={`List card mx-3 rounded-3 position-relative  ${
          isDragging ? "opacity-50" : ""
        }`}
        style={{
          boxShadow: isDragging
            ? "inset 7px 7px 14px #d9d9d9, inset -7px -7px 14px #ffffff"
            : "rgba(0, 0, 0, 0.15) 0px 5px 15px 0px",
          backgroundColor: "#F1F0E8",
          minWidth: "300px",
          maxWidth: "300px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            paddingLeft: "20px",
            paddingTop: "10px",
          }}
        >
          <div
            style={{
              width: "35px",
              height: "35px",
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
            }}
          >
            {listItem.progress !== 100 ? (
              <CircularProgressbar
                value={listItem.progress}
                strokeWidth={50}
                styles={buildStyles({
                  strokeLinecap: "butt",
                  trailColor: "rgba(255, 255, 255)",
                  pathColor: `${
                    listItem.progress <= 33.33
                      ? "yellow"
                      : listItem.progress <= 66.66 && listItem.progress >= 33.33
                      ? "gold"
                      : "orange"
                  }`,
                })}
              />
            ) : listItem.name !== "Spillover" ? (
              <i
                className="bi bi-check-circle"
                style={{
                  fontSize: "35px",
                  marginTop: 0,
                  paddingBottom: "5px",
                  color: "green",
                }}
              ></i>
            ) : (
              <i
                className="bi bi-exclamation"
                style={{
                  fontSize: "35px",
                  marginTop: 0,
                  paddingBottom: "5px",
                  color: "red",
                }}
              ></i>
            )}
          </div>
          <h5 className="card-header border-0 bg-transparent">
            {listItem.name}
            <span
              className="badge rounded-circle text-light ms-2"
              style={{ fontSize: ".7em", backgroundColor: "gray" }}
            >
              {listItem.items.length}
            </span>
          </h5>
        </div>
        <div className="card-body overflow-auto p-2">
          <SortableContext
            items={listItem.items}
            strategy={verticalListSortingStrategy}
          >
            {children}
          </SortableContext>
        </div>
      </div>
    </div>
  );
};

export default List;
