import { useEffect, useState } from "react";
import { arrayMove } from "@dnd-kit/sortable";
import useFetchBoard from "../fetch/useFetchBoard";
import uuid from "react-uuid";

const useKanban = () => {
  const { data, isLoading, isError } = useFetchBoard();
  const [list, setList] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [lifecycle, setLifecycle] = useState([]);
  const [currentAllowedSeq, setCurrentAllowedSeq] = useState([]);
  const [tags, setTags] = useState([]);
  const [tempList, setTempList] = useState(null);
  const [draggingItem, setDraggingItem] = useState(null);
  const [draggingParent, setDraggingParent] = useState(null);
  const [seqMap, setSeqMap] = useState(null);

  const resetList = () => {
    setList([...tempList]);
  };
  const updateTempList = () => {
    setTempList([...list]);
  };
  const handleDragEnd = (e) => {
    const { active, over } = e;
    if (
      active.data.current.parent === over.data.current.parent &&
      active.data.current.type !== "list" &&
      over.data.current.type !== "list"
    ) {
      if (
        seqMap?.get(draggingParent)?.includes(over.data.current.parent) ||
        draggingParent === over.data.current.parent
      ) {
        updateTempList();
      } else {
        resetList();
        return;
      }
      // patchHandler(active.data.current.item);
      let temp = list.filter(
        (items) => active.data.current.parent === items.id
      )[0].items;
      const oldIndex = temp.findIndex((item) => item.id === active.id);
      const newIndex = temp.findIndex((item) => item.id === over.id);
      temp = arrayMove(temp, oldIndex, newIndex);
      setList((list) =>
        list.map((items) => {
          if (active.data.current.parent === items.id) {
            return {
              ...items,
              items: temp,
            };
          }
          return items;
        })
      );
    }
    if (
      active.data.current.type === "list" &&
      over.data.current.type === "list"
    ) {
      const oldIndex = list.findIndex((items) => items.id === active.id);
      const newIndex = list.findIndex((items) => items.id === over.id);
      setList(arrayMove(list, oldIndex, newIndex));
    }
  };
  const handleDragOver = (e) => {
    const { active, over } = e;
    if (
      active.data.current.parent !== over.data.current.parent &&
      active.data.current.type !== "list" &&
      over.data.current.type !== "list"
    ) {
      setList((list) =>
        list.map((items) => {
          if (items.id === active.data.current.parent) {
            return {
              ...items,
              items: items.items.filter((item) => item.id !== active.id),
            };
          } else if (items.id === over.data.current.parent) {
            return {
              ...items,
              items: [
                ...items.items,
                {
                  ...active.data.current.item,
                  current_status: over.data.current.item.current_status,
                  parent: over.data.current.parent,
                },
              ],
            };
          }
          return items;
        })
      );
    }
    if (
      active.data.current.parent !== over.data.current.parent &&
      active.data.current.type === "card" &&
      over.data.current.type === "list" &&
      active.data.current.parent !== over.id
    ) {
      setList(
        list.map((items) => {
          if (items.id === over.data.current.item.id) {
            return {
              ...items,
              items: [
                ...items.items,
                {
                  ...active.data.current.item,
                  current_status: list.filter(
                    (listItem) => listItem.id === over.id
                  )[0].status_id,
                },
              ],
            };
          }
          if (items.id === active.data.current.parent) {
            return {
              ...items,
              items: items.items.filter(
                (item) => item.id !== active.data.current.item.id
              ),
            };
          }
          return items;
        })
      );
    }
  };
  const handleDragStart = (e) => {
    setDraggingItem(e.active.data.current.item);
    setDraggingParent(e.active.data.current.parent);
  };

  const flattenTasks = (tasksList) => {
    let flatList = [];

    const recurse = (task) => {
      flatList.push({ ...task }); // Add the task itself
      if (task.tasks && task.tasks.length > 0) {
        task.tasks.forEach((subTask) => recurse(subTask)); // Recurse through subtasks
      }
      delete task.tasks; // Optionally remove the tasks property if not needed
    };

    tasksList.forEach((task) => recurse(task));

    return flatList;
  };

  useEffect(() => {
    const tempMap = new Map();
    currentAllowedSeq.map((seq) => {
      if (!tempMap.has(seq.previous_id)) {
        tempMap.set(seq.previous_id, [seq.next_id]);
      } else {
        tempMap.set(seq.previous_id, [
          ...tempMap.get(seq.previous_id),
          seq.next_id,
        ]);
      }
    });
    setSeqMap(tempMap);
  }, [currentAllowedSeq]);
  useEffect(() => {
    if (list.length !== 0 && draggingParent === null) {
      setTempList([...list]);
    }
  }, [list]);
  useEffect(() => {
    if (data && !isLoading && !isError) {
      setCurrentAllowedSeq(data?.data?.sequence_schema);
      setLifecycle(
        data?.data?.lifecycle_states?.sort((a, b) => a.sequence - b.sequence)
      );
      setTasks(flattenTasks(data?.data?.tasks));
      setTags(
        data?.data?.tags.map((tag) => ({ value: tag?.id, label: tag?.name }))
      );
    }
  }, [data]);
  useEffect(() => {
    if (currentAllowedSeq.length !== 0 && lifecycle.length !== 0) {
      let templist = [...tasks];
      setList(
        lifecycle.map((state, index) => {
          return {
            id: state.id,
            status_id: state.id,
            name: state.name === "Completed" ? "Completed 🎉" : state.name,
            status: state.name,
            type: "list",
            progress:
              state.name !== "Spillover"
                ? index === 0
                  ? 0
                  : ((index + 1) / lifecycle.length) * 100
                : 100,
            items: templist
              .map((item) => {
                if (item?.state === state.id)
                  return {
                    id: uuid(),
                    card_identification: item?.id,
                    card_id_id: item?.card_id,
                    value: item?.name,
                    progress: 0,
                    priority: item?.priority,
                    members: null,
                    date: new Date(item?.due),
                    tags: item?.tags?.map((tag) => {
                      return data?.data?.tags?.find((t) => t?.id === tag);
                    }),
                    startDate: new Date(),
                    endDate: new Date(item?.due),
                    card_type: data?.data?.types?.find(
                      (type) => type?.id === item?.type
                    )?.name,
                    type: "card",
                    assigned_to: item.assignees,
                    assigned_by: item?.assignors,
                    description: item?.description,
                    notes: item?.notes,
                    agenda: item?.agenda,
                    current_status: item?.state,
                    card_identification_number:
                      item?.card_identification_number,
                  };
              })
              .filter((item) => item !== undefined),
          };
        })
      );
    }
  }, [currentAllowedSeq, lifecycle, tasks]);

  return {
    list,
    tags,
    setTags,
    draggingItem,
    handleDragStart,
    handleDragOver,
    handleDragEnd,
  };
};

export default useKanban;
