import React, { useContext } from "react";
import { useDroppable } from "@dnd-kit/core";
import { ListContext } from "../ListContextProvider";

const DroppableArea = ({ id }) => {
  const { isOver, setNodeRef } = useDroppable({
    id: id,
  });
  const { activeTask } = useContext(ListContext);
  const style = {
    width: "100%",
    height: "0.1em",
    marginBottom: "0.1em",
    backgroundColor:
      isOver && activeTask?.id !== parseInt(id.split("_")[0])
        ? "#f0f0f0"
        : "tranparent",
    border:
      isOver && activeTask?.id !== parseInt(id.split("_")[0])
        ? "2px dashed #000"
        : "none",
    borderRadius: "4px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    transition: "background-color 0.3s ease",
  };

  return <div ref={setNodeRef} style={style}></div>;
};

export default DroppableArea;
