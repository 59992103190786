import React, { useContext, useState } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import "react-circular-progressbar/dist/styles.css";
import { ListContext } from "../../../context/ListContextProvider";
import { DrawerContext } from "../../../context/DrawerContextProvider";
import "./kanban.css";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import moment from "moment";
import UsersProfilesDropdown from "../../TaskManagement/kanban/dropdown/UsersProfilesDropdown";

function Card({ item, listId, cursor }) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: item.id,
    data: {
      type: item.type,
      item: item,
      parent: listId,
    },
  });
  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
    cursor: cursor && cursor,
    minWidth: "270px",
    maxWidth: "270px",
  };
  const { view, deleteRegularCard } = useContext(ListContext);
  const { toggleDrawer } = useContext(DrawerContext);
  const [showDel, setShowDel] = useState(false);

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {view !== "lead" ? item?.assigned_by : item.assigned_to}
    </Tooltip>
  );

  return (
    <>
      {item?.value && (
        <div
          className="card mb-3 mx-1 bg-light rounded-1"
          style={style}
          {...attributes}
          {...listeners}
          ref={setNodeRef}
          onMouseEnter={() => setShowDel(true)}
          onMouseLeave={() => setShowDel(false)}
        >
          <div
            className={`card-body rounded-1 px-3 py-0 position-relative ${
              isDragging ? "opacity-50" : ""
            } `}
            style={{
              boxShadow: isDragging
                ? "rgba(0, 0, 0, 0.3) 2.4px 2.4px 3.2px inset, rgba(255, 255, 255, 0.3) -2.4px -2.4px 3.2px inset"
                : "",
              border: cursor === "move" ? "1px solid gray" : "none",
            }}
            onClick={() => {
              if (true) {
                toggleDrawer(listId, item);
              }
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
              }}
            >
              <>
                <div
                  className="mt-3"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginLeft: "8px",
                    marginRight: "20px",
                  }}
                >
                  <p
                    style={{
                      cursor: "pointer",
                      fontSize: "1em",
                    }}
                  >
                    {item.value}
                  </p>
                </div>
              </>
            </div>
            <div className="ps-1 pt-1 row">
              <div className="col-12 ps-3 text-start d-flex align-items-center justify-content-between">
                <p>
                  <span
                    className="badge bg-warning"
                    style={{ fontSize: "10px" }}
                  >
                    <span className="me-2">
                      {moment(item?.startDate, "YYYY-MM-DD").format(
                        "DD-MM-YYYY"
                      )}
                    </span>
                    -
                    <span className="ms-2">
                      {moment(item?.endDate, "YYYY-MM-DD").format("DD-MM-YYYY")}
                    </span>
                  </span>
                </p>
                {view === "lead" && (
                  <OverlayTrigger
                    placement="right"
                    delay={{ show: 250, hide: 300 }}
                    overlay={renderTooltip}
                  >
                    <p
                      type="button"
                      className="text-nowrap"
                      style={{
                        cursor: "pointer",
                        backgroundColor: "#5243AA",
                        color: "white",
                        fontSize: "13px",
                        height: "30px",
                        width: "30px",
                        textAlign: "center",
                        paddingTop: "5px",
                        borderRadius: "50%",
                        fontWeight: "bolder",
                      }}
                    >
                      MS
                    </p>
                  </OverlayTrigger>
                )}
              </div>
            </div>
          </div>
          {showDel && item.card_type === "regularcard" ? (
            <i
              className="icofont-trash position-absolute top-0 end-0 mt-3 me-3 text-danger z-1"
              style={{ fontSize: "1.3em" }}
              onClick={() => {
                deleteRegularCard(item.card_identification);
              }}
            ></i>
          ) : (
            <span
              className="badge rounded position-absolute top-0 end-0 mt-3 me-3 text-wrap"
              style={{
                color: "white",
                backgroundColor:
                  item.card_type === "Project Card"
                    ? "purple"
                    : item.card_type === "Regular Card"
                    ? "skyblue"
                    : item.card_type === "Structural Card"
                    ? "pink"
                    : item.card_type === "Meeting Card"
                    ? "#06D001"
                    : "tomato",
                fontSize: ".7em",
              }}
            >
              {item.card_type === "wrokflowblockcard"
                ? "WC"
                : item.card_type === "Regular Card"
                ? "RC"
                : item.card_type === "Structural Card"
                ? "SC"
                : item.card_type === "Project Card"
                ? "PC"
                : item.card_type === "Meeting Card"
                ? "MC"
                : "TC"}
            </span>
          )}
          <div className="position-absolute bottom-0 end-0 me-4 mb-2 ">
            <UsersProfilesDropdown item={item} />
          </div>
        </div>
      )}
    </>
  );
}

export default Card;
