import { DragOverlay } from "@dnd-kit/core";
import React from "react";
import Card from "./Card";
import { createPortal } from "react-dom";

const DraggingCard = ({ draggingItem, list }) => {
  return (
    <>
      {createPortal(
        <DragOverlay>
          {draggingItem !== null && draggingItem.type === "card" && (
            <Card
              key={draggingItem.id}
              item={draggingItem}
              listId={
                list.filter((listItems) => {
                  listItems.items.filter((items) => {
                    if (items.id === draggingItem.id) {
                      return listItems.id;
                    }
                  });
                })[0]
              }
              cursor={"move"}
            />
          )}
        </DragOverlay>,
        document.body
      )}
    </>
  );
};

export default DraggingCard;
