import { useQuery } from "react-query";
import { fetchBoard } from "../../api/endpoints/kanban/board";

const useFetchBoard = () => {
  return useQuery(["board"], () => fetchBoard(), {
    staleTime: Infinity, // Data will never go stale
    cacheTime: Infinity, // Data will be cached indefinitely
    refetchOnWindowFocus: false, // Do not refetch when window is focused
    refetchOnMount: false, // Do not refetch when component mounts
    refetchOnReconnect: false, // Do not refetch when reconnecting to the internet
  });
};

export default useFetchBoard;
