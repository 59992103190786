import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useLocation } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import useFinancialYearWeeks from "../../hooks/useFinancialYearWeeks";
import axios from "axios";
import { VARIABLES } from "../../Constants";
import { UsersContext } from "../UsersContext/UsersContextProvider";

export const TimesheetManagementContext = createContext(null);

const TimesheetManagementContextProvider = ({ children }) => {
  const { isUserLoggedIn } = useContext(UsersContext);
  const [timesheetResources, setTimesheetResources] = useState([]);
  const [filterWith, setFilterWith] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const { currentWeekNumber } = useFinancialYearWeeks();
  const [showWeek, setShowWeek] = useState(null);
  const [showMonth, setShowMonth] = useState(null);
  const [showYear, setShowYear] = useState(null);
  const [showUser, setShowUser] = useState(null);
  const cancelTokenSource = useRef(null);
  const userID = secureLocalStorage.getItem("userID");
  const location = useLocation();
  const months = [
    { value: 1, label: "January" },
    { value: 2, label: "February" },
    { value: 3, label: "March" },
    { value: 4, label: "April" },
    { value: 5, label: "May" },
    { value: 6, label: "June" },
    { value: 7, label: "July" },
    { value: 8, label: "August" },
    { value: 9, label: "September" },
    { value: 10, label: "October" },
    { value: 11, label: "November" },
    { value: 12, label: "December" },
  ];

  const getTimesheetResources = async () => {
    if (cancelTokenSource.current) {
      cancelTokenSource.current.cancel(
        "Operation canceled due to new request."
      );
    }
    cancelTokenSource.current = axios.CancelToken.source();
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${VARIABLES.url}/api-app/timesheet-dashboard?lead_id=${showUser}${
          filterWith === 1
            ? "&week_number=" + showWeek
            : filterWith === 0
            ? "&month=" + showMonth
            : ""
        }&year=${showYear}`,
        {
          cancelToken: cancelTokenSource.current.token,
        }
      );
      setTimesheetResources(response.data);
      setIsLoading(false);
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("Request canceled", error.message);
      } else {
        console.error("Error occurred: ", error);
        setIsLoading(false);
      }
      setTimesheetResources([]);
    }
  };

  useEffect(() => {
    if (currentWeekNumber && isUserLoggedIn) {
      setShowWeek(currentWeekNumber);
      setShowMonth(months.filter((m) => m.value === new Date().getMonth() + 1));
      setShowYear(new Date().getFullYear().toString());
      setShowUser(userID);
    }
  }, [currentWeekNumber, isUserLoggedIn]);

  useMemo(() => {
    if (showMonth && showWeek && showYear && showUser) {
      getTimesheetResources();
    }
  }, [showWeek, showMonth, showYear, showUser]);

  const value = {
    timesheetResources,
    filterWith,
    isLoading,
    showWeek,
    showMonth,
    showYear,
    showUser,
    getTimesheetResources,
    setFilterWith,
    setShowMonth,
    setShowWeek,
    setShowUser,
    setShowYear,
  };
  return (
    <TimesheetManagementContext.Provider value={value}>
      {children}
    </TimesheetManagementContext.Provider>
  );
};

export default TimesheetManagementContextProvider;
