import { useMutation, useQueryClient } from "react-query";
import { patchCardData } from "../../api/endpoints/task-management/patchCardData";
import secureLocalStorage from "react-secure-storage";

const usePatchCardData = (currentBoardId) => {
  const queryClient = useQueryClient();
  const userID = secureLocalStorage.getItem("userID");
  return useMutation(
    async ({ id, data }) => {
      const response = await patchCardData(id, data);
      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          "boards-tasks",
          currentBoardId.id,
          userID,
        ]);
      },
      onError: (error) => {
        console.error("Error updating task:", error);
      },
    }
  );
};

export default usePatchCardData;
