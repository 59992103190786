import React, { createContext, useEffect, useRef, useState } from "react";
import uuid from "react-uuid";
import secureLocalStorage from "react-secure-storage";
import confetti from "canvas-confetti";
import usePatchCardData from "../../hooks/task-management/usePatchCardData";
import { useQueryClient } from "react-query";

export const TaskManagementContext = createContext(null);

function TaskManagementContextProvider({ children }) {
  const [list, setList] = useState([]);
  const [currentAllowedSeq, setCurrentAllowedSeq] = useState([]);
  const [boardSettings, setBoardSettings] = useState([]);
  const [lifecycle, setLifecycle] = useState([]);
  const [tasksList, setTasksList] = useState([]);
  const userID = secureLocalStorage.getItem("userID");
  const [showAllTasks, setShowAllTasks] = useState(true);
  const [currentBoardId, setCurrentBoardId] = useState(null);
  const {
    mutate: updateTask,
    isLoading,
    isError,
  } = usePatchCardData(currentBoardId);
  const queryClient = useQueryClient();
  const completedListRef = useRef(null);

  const getTasksList = (boardId) => {
    queryClient.invalidateQueries(["boards-tasks", boardId, userID]);
  };
  const patchCardData = async (id, cardData) => {
    let data = {
      lifecycle_state_id: cardData.lifecycle_state_id,
      updated_by_id: userID,
    };
    updateTask(
      { id, data },
      {
        onSuccess: () => {
          const showConfitte =
            boardSettings?.lifecycle_states.filter(
              (state) => state.id === cardData.lifecycle_state_id
            )[0]?.name === "Completed";

          if (showConfitte) {
            performConfitte();
          }
        },
      }
    );
  };

  const getTasksListForUpdate = () => {
    queryClient.invalidateQueries(["boards-tasks", currentBoardId.id, userID]);
  };

  function randomInRange(min, max) {
    return Math.random() * (max - min) + min;
  }
  const performConfitte = () => {
    if (completedListRef?.current) {
      const rect = completedListRef.current.getBoundingClientRect();
      const x = rect.left + rect.width / 2;
      const y = rect.top + rect.height / 2;

      confetti({
        angle: randomInRange(55, 125),
        spread: randomInRange(50, 70),
        particleCount: randomInRange(50, 100),
        origin: {
          x: x / window.innerWidth,
          y: y / window.innerHeight,
        },
      });
    }
  };

  useEffect(() => {
    if (boardSettings.length !== 0) {
      let templist = [];
      if (showAllTasks) {
        templist = [...tasksList];
      } else {
        templist = tasksList.filter((task) =>
          task.assigned_to.includes(userID)
        );
      }
      setList(
        lifecycle.map((state, index) => {
          return {
            id: state.id,
            status_id: state.id,
            name: state.name === "Completed" ? "Completed 🎉" : state.name,
            status: state.name,
            type: "list",
            progress:
              state.name !== "Spillover"
                ? index === 0
                  ? 0
                  : ((index + 1) / lifecycle.length) * 100
                : 100,
            items: templist
              .map((item) => {
                if (item?.lifecycle_state === state.id)
                  return {
                    id: uuid(),
                    card_identification: item.id,
                    card_id_id: item.card_id,
                    value: item.card_title,
                    progress: 0,
                    priority: "L",
                    members: null,
                    date: null,
                    tags: null,
                    startDate: item?.start_time,
                    endDate: item?.end_time,
                    card_type: item?.card_belongs_to,
                    type: "card",
                    assigned_to: item.assigned_to,
                    assigned_by:
                      item.created_by.first_name +
                      " " +
                      item.created_by.last_name,
                    description: item.card_description,
                    notes: item?.notes,
                    agenda: item?.agenda,
                    current_status: item?.lifecycle_state,
                    card_identification_number: item.card_identification_number,
                  };
              })
              .filter((item) => item !== undefined),
          };
        })
      );
    }
  }, [tasksList, boardSettings, showAllTasks]);

  useEffect(() => {
    console.log(isLoading);
    console.log(isError);
  }, [isLoading, isError]);

  const value = {
    boardSettings,
    tasksList,
    list,
    setList,
    currentAllowedSeq,
    patchCardData,
    showAllTasks,
    setShowAllTasks,
    getTasksList,
    setCurrentBoardId,
    currentBoardId,
    getTasksListForUpdate,
    completedListRef,

    setBoardSettings,
    setLifecycle,
    setCurrentAllowedSeq,

    setTasksList,

    setCurrentBoardId,
  };
  return (
    <TaskManagementContext.Provider value={value}>
      {children}
    </TaskManagementContext.Provider>
  );
}

export default TaskManagementContextProvider;
