import { useQuery } from "react-query";
import { fetchTasksForBoard } from "../../api/endpoints/task-management/tasksForBoard";

const useFetchTasksForBoard = (projectId, userID) => {
  return useQuery(
    ["boards-tasks", projectId, userID],
    () => fetchTasksForBoard({ project_id: projectId, user_id: userID }),
    {
      enabled: !!projectId && !!userID,
      staleTime: 600000, // Data will never go stale
      cacheTime: 3600000, // Data will be cached indefinitely
      refetchOnWindowFocus: true, // Do not refetch when window is focused
      refetchOnMount: false, // Do not refetch when component mounts
      refetchOnReconnect: true, // Do not refetch when reconnecting to the internet
    }
  );
};

export default useFetchTasksForBoard;
