import React, {
  useState,
  createContext,
  useEffect,
  useContext,
  useRef,
} from "react";
import uuid from "react-uuid";
import { VARIABLES } from "../Constants";
import secureLocalStorage from "react-secure-storage";
import axios from "axios";
import { useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import { TaskManagementContext } from "./TaskManagementContext/TaskManagementContextProvider";
import { DropdownAPIsContext } from "./DropdownAPIsContext/DropdownAPIsContextProvider";
import confetti from "canvas-confetti";

export const ListContext = createContext(null);

function ListContextProvider({ children }) {
  const [userList, setUserList] = useState([]);
  const [leadList, setLeadList] = useState([]);
  const [list, setList] = useState([]);
  const [boardSettings, setBoardSettings] = useState([]);
  const [currentAllowedSeq, setCurrentAllowedSeq] = useState([]);
  const location = useLocation();
  const [statusList, setStatusList] = useState([]);
  const [view, setView] = useState("");
  const completedListRef = useRef(null);
  const userID = secureLocalStorage.getItem("userID");
  const [isLoading, setIsloading] = useState(false);
  const { getTasksList, boardsList } = useContext(TaskManagementContext);
  const { kanbanBoardId } = useContext(DropdownAPIsContext);

  const getBoardSettings = (boardId) => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${VARIABLES.url}/api-app/board-info?board_id=${boardId}`,
      headers: {},
    };
    axios
      .request(config)
      .then((response) => {
        setBoardSettings(response.data);
        setCurrentAllowedSeq(response.data.sequence_schema);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const setUserData = async () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: VARIABLES.url + `/api-app/lead-kanban/?employee_id=${userID}`,
      headers: {},
    };
    setIsloading(true);
    axios
      .request(config)
      .then((response) => {
        setUserList(response.data);
        setIsloading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsloading(false);
      });
  };

  const setLeadsData = async () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: VARIABLES.url + `/api-app/lead-kanban/?lead_id=${userID}`,
      headers: {},
    };
    setIsloading(true);
    axios
      .request(config)
      .then((response) => {
        setLeadList(response.data);
        setIsloading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsloading(false);
      });
  };

  function randomInRange(min, max) {
    return Math.random() * (max - min) + min;
  }
  const performConfitte = () => {
    if (completedListRef?.current) {
      const rect = completedListRef.current.getBoundingClientRect();
      const x = rect.left + rect.width / 2;
      const y = rect.top + rect.height / 2;

      confetti({
        angle: randomInRange(55, 125),
        spread: randomInRange(50, 70),
        particleCount: randomInRange(50, 100),
        origin: {
          x: x / window.innerWidth,
          y: y / window.innerHeight,
        },
      });
    }
  };

  const patchCardData = async (id, cardData) => {
    let data = JSON.stringify(cardData);
    //Warn: String comparison
    const showCanfetti =
      boardSettings?.lifecycle_states.filter(
        (state) => state.id === cardData.lifecycle_state_id
      )[0]?.name === "Completed";
    let config = {
      method: "patch",
      maxBodyLength: Infinity,
      url: `${VARIABLES.url}/api-app/lead-kanban/${id}/`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios
      .request(config)
      .then((response) => {
        if (showCanfetti) {
          performConfitte();
        }
        let regularBoardId = null;
        boardsList?.map((board) => {
          if (board.name === "Regular Card Board") {
            regularBoardId = board.id;
          }
        });
        if (regularBoardId !== null) {
          getTasksList(regularBoardId);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const createRegularCard = (data) => {
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${VARIABLES.url}/api-app/regular-card`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        setUserData();
        Swal.fire({
          title: "Success!",
          text: "Regular Card Created successfully",
          icon: "success",
          confirmButtonText: "OK",
        }).then((result) => {
          if (result.isConfirmed) {
            let regularBoardId = null;
            boardsList?.map((board) => {
              if (board.name === "Regular Card Board") {
                regularBoardId = board.id;
              }
            });
            if (regularBoardId !== null) {
              getTasksList(regularBoardId);
            }
          }
        });
      })
      .catch((error) => {
        console.log(error);
        Swal.fire({
          title: "Error",
          text: "Failed to Create Regular Card",
          icon: "error",
          confirmButtonText: "OK",
        });
      });
  };

  const deleteRegularCard = (id) => {
    Swal.fire({
      title: "Are you sure? You will not be able to recover this project!",
      showDenyButton: true,
      confirmButtonText: "Yes, delete it!",
      denyButtonText: `No, cancel!`,
    }).then((result) => {
      if (result.isConfirmed) {
        let config = {
          method: "delete",
          maxBodyLength: Infinity,
          url: `${VARIABLES.url}/api-app/regular-card/${id}`,
          headers: {},
        };

        axios
          .request(config)
          .then((response) => {
            let regularBoardId = null;
            boardsList?.map((board) => {
              if (board.name === "Regular Card Board") {
                regularBoardId = board.id;
              }
            });
            if (regularBoardId !== null) {
              getTasksList(regularBoardId);
            }
            setUserData();
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });
  };

  const patchRegularCard = (id, regularCardData) => {
    let config = {
      method: "patch",
      maxBodyLength: Infinity,
      url: `${VARIABLES.url}/api-app/regular-card/${id}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: regularCardData,
    };

    axios
      .request(config)
      .then((response) => {
        setUserData();
        let regularBoardId = null;
        boardsList?.map((board) => {
          if (board.name === "Regular Card Board") {
            regularBoardId = board.id;
          }
        });
        if (regularBoardId !== null) {
          getTasksList(regularBoardId);
        }
        Swal.fire({
          title: "Success!",
          text: "Regular Card Updated successfully",
          icon: "success",
          confirmButtonText: "OK",
        });
      })
      .catch((error) => {
        console.log(error);
        Swal.fire({
          title: "Error",
          text: "Failed to Update Regular Card",
          icon: "error",
          confirmButtonText: "OK",
        });
      });
  };

  useEffect(() => {
    if (statusList.length !== 0) {
      if (view === "user") {
        if (userList !== null) {
          setList(
            statusList.map((status, index) => {
              return {
                id: status.id,
                name: status.status,
                status: status.status,
                status_id: status.id,
                type: "list",
                progress:
                  status.status !== "Spillover"
                    ? index === 0
                      ? 0
                      : ((index + 1) / statusList.length) * 100
                    : 100,
                items:
                  userList.data !== undefined
                    ? [
                        ...userList.data[0].target_cards.map((card) => {
                          if (card?.lifecycle_state?.id === status.id) {
                            return {
                              id: uuid(),
                              card_identification: card.id,
                              value: card.card_title,
                              progress: 0,
                              priority: card?.workflow_block?.priority || "L",
                              members: null,
                              date: null,
                              start_date: card.start_time,
                              end_date: card.end_time,
                              startDate: card.start_time,
                              endDate: card.end_time,
                              tags: null,
                              card_type: "targetcard",
                              type: "card",
                              assigned_to: [card.assigned_to[0].id],
                              assigned_to_id: card.assigned_to[0].id,
                              assigned_by:
                                card.created_by.first_name +
                                " " +
                                card.created_by.last_name,
                              description: card.card_description,
                              current_status: card?.lifecycle_state?.id,
                              current_status_name: card?.lifecycle_state?.name,
                              card_identification_number:
                                card.card_identification_number,
                            };
                          }
                        }),
                        ...userList.data[0].workflow_block_cards.map((card) => {
                          if (card?.lifecycle_state?.id === status.id) {
                            return {
                              id: uuid(),
                              card_identification: card.id,
                              value: card.card_title,
                              progress: 0,
                              priority: card?.workflow_block?.priority || "L",
                              members: null,
                              date: null,
                              start_date: card.start_date,
                              end_date: card.end_date,
                              tags: null,
                              card_type: "wrokflowblockcard",
                              type: "card",
                              assigned_to:
                                card.assigned_to[0].first_name +
                                " " +
                                card.assigned_to[0].last_name,
                              assigned_by:
                                card.created_by.first_name +
                                " " +
                                card.created_by.last_name,
                              description: card.card_description,
                              current_status: card?.lifecycle_state?.id,
                              card_identification_number:
                                card.card_identification_number,
                            };
                          }
                        }),
                        ...userList.data[0].regular_cards.map((card) => {
                          if (card?.lifecycle_state?.id === status.id) {
                            return {
                              id: uuid(),
                              card_identification: card.id,
                              value: card.card_title,
                              progress: 0,
                              priority: card?.workflow_block?.priority || "L",
                              members: null,
                              date: null,
                              start_date: card.start_time,
                              end_date: card.end_time,
                              tags: null,
                              card_type: "regularcard",
                              type: "card",
                              startDate: card.start_time,
                              endDate: card.end_time,
                              assigned_to: card.assigned_to,
                              assigned_by:
                                card.created_by.first_name +
                                " " +
                                card.created_by.last_name,
                              description: card.card_description,
                              current_status: card?.lifecycle_state?.id,
                              card_identification_number:
                                card.card_identification_number,
                            };
                          }
                        }),
                        ...userList.data[0].project_cards.map((card) => {
                          if (card?.lifecycle_state?.id === status.id) {
                            return {
                              id: uuid(),
                              card_identification: card.id,
                              value: card.card_title,
                              progress: 0,
                              priority: card?.workflow_block?.priority || "L",
                              members: null,
                              date: null,
                              start_date: card.start_time,
                              end_date: card.end_time,
                              tags: null,
                              card_type: "projectcard",
                              type: "card",
                              startDate: card.start_time,
                              endDate: card.end_time,
                              assigned_to: card.assigned_to.map((e) => e.id),
                              assigned_by:
                                card.created_by.first_name +
                                " " +
                                card.created_by.last_name,
                              description: card.card_description,
                              current_status: card?.lifecycle_state?.id,
                              card_identification_number:
                                card.card_identification_number,
                            };
                          }
                        }),
                        ...userList.data[0].structural_cards.map((card) => {
                          if (card?.lifecycle_state?.id === status.id) {
                            return {
                              id: uuid(),
                              card_identification: card.id,
                              value: card.card_title,
                              progress: 0,
                              priority: card?.workflow_block?.priority || "L",
                              members: null,
                              date: null,
                              start_date: card.start_time,
                              end_date: card.end_time,
                              tags: null,
                              card_type: "structuralcard",
                              type: "card",
                              project_name: card?.board_id?.name,
                              startDate: card.start_time,
                              endDate: card.end_time,
                              assigned_to: card.assigned_to.map((e) => e.id),
                              assigned_by:
                                card.created_by.first_name +
                                " " +
                                card.created_by.last_name,
                              description: card.card_description,
                              current_status: card?.lifecycle_state?.id,
                              card_identification_number:
                                card.card_identification_number,
                            };
                          }
                        }),
                        ...userList.data[0].meeting_cards.map((card) => {
                          if (card?.lifecycle_state?.id === status.id) {
                            return {
                              id: uuid(),
                              card_identification: card.id,
                              value: card.card_title,
                              progress: 0,
                              priority: card?.workflow_block?.priority || "L",
                              members: null,
                              date: null,
                              start_date: card.start_time,
                              end_date: card.end_time,
                              tags: null,
                              card_type: "meetingcard",
                              type: "card",
                              startDate: card.start_time,
                              endDate: card.end_time,
                              assigned_to: card.assigned_to.map((e) => e.id),
                              assigned_by:
                                card.created_by.first_name +
                                " " +
                                card.created_by.last_name,
                              description: card.notes,
                              agenda: card.agenda,
                              current_status: card?.lifecycle_state?.id,
                              card_identification_number:
                                card.card_identification_number,
                            };
                          }
                        }),
                      ].filter((card) => card !== undefined)
                    : [],
              };
            })
          );
        } else {
          setList(
            statusList.map((status, index) => {
              return {
                id: uuid(),
                name: status.status,
                status: status.status,
                type: "list",
                progress:
                  status.status !== "Spillover"
                    ? index === 0
                      ? 0
                      : ((index + 1) / statusList.length) * 100
                    : 100,
                items: [],
              };
            })
          );
        }
      } else {
        if (leadList !== null) {
          setList(
            statusList.map((status, index) => {
              return {
                id: status.id,
                name: status.status,
                status: status.status,
                status_id: status.id,
                type: "list",
                progress:
                  status.status !== "Spillover"
                    ? index === 0
                      ? 0
                      : ((index + 1) / statusList.length) * 100
                    : 100,
                items:
                  leadList.length !== 0
                    ? [
                        ...leadList.data[0].target_cards.map((card) => {
                          if (card?.lifecycle_state?.id === status.id) {
                            return {
                              id: uuid(),
                              card_identification: card.id,
                              value: card.card_title,
                              progress: 0,
                              priority: card?.workflow_block?.priority || "L",
                              members: null,
                              date: null,
                              start_date: card.start_time,
                              end_date: card.end_time,
                              startDate: card.start_time,
                              endDate: card.end_time,
                              tags: null,
                              card_type: "targetcard",
                              type: "card",
                              assigned_to: [card.assigned_to[0].id],
                              assigned_to_id: card.assigned_to[0].id,
                              assigned_by:
                                card.created_by.first_name +
                                " " +
                                card.created_by.last_name,
                              description: card.card_description,
                              current_status: card?.lifecycle_state?.id,
                              current_status_name: card?.lifecycle_state?.name,
                              card_identification_number:
                                card.card_identification_number,
                            };
                          }
                        }),
                        ...leadList.data[0].workflow_block_cards.map((card) => {
                          if (card?.lifecycle_state?.id === status.id) {
                            return {
                              id: uuid(),
                              card_identification: card.id,
                              value: card.card_title,
                              progress: 0,
                              priority: card?.workflow_block?.priority || "L",
                              members: null,
                              date: null,
                              start_date: card.start_time,
                              end_date: card.end_time,
                              tags: null,
                              card_type: "wrokflowblockcard",
                              type: "card",
                              assigned_to:
                                card.assigned_to[0].first_name +
                                " " +
                                card.assigned_to[0].last_name,
                              assigned_by:
                                card.created_by.first_name +
                                " " +
                                card.created_by.last_name,
                              description: card.card_description,
                              current_status: card?.lifecycle_state?.id,
                              card_identification_number:
                                card.card_identification_number,
                            };
                          }
                        }),
                      ].filter((card) => card !== undefined)
                    : [],
              };
            })
          );
        } else {
          setList(
            statusList.map((status) => {
              return {
                id: uuid(),
                name: status.status,
                status: status.status,
                type: "list",
                progress: status.progress,
                items: [],
              };
            })
          );
        }
      }
    } else {
      setList([]);
    }
  }, [userList, leadList, view, statusList]);

  useEffect(() => {
    if (location.pathname === "/kanban") {
      setView("user");
    } else if (location.pathname === "/teamreportkanban") {
      setView("lead");
    } else {
      setView("");
    }
  }, [location.pathname]);

  useEffect(() => {
    if (view === "user") {
      setUserData();
    } else if (view === "lead") {
      setLeadsData();
    }
  }, [view]);

  useEffect(() => {
    if (kanbanBoardId !== null) {
      getBoardSettings(kanbanBoardId);
    }
  }, [kanbanBoardId]);

  useEffect(() => {
    if (boardSettings.length !== 0) {
      setStatusList(
        boardSettings?.lifecycle_states
          .map((state) => {
            return {
              id: state.id,
              status: state.name === "Completed" ? "Completed 🎉" : state.name,
              type: "list",
              progress: 0,
              sequence: state?.sequence,
            };
          })
          .sort((a, b) => a.sequence - b.sequence)
      );
    }
  }, [boardSettings]);

  const value = {
    list,
    view,
    isLoading,
    setList,
    setView,
    patchCardData,
    createRegularCard,
    deleteRegularCard,
    patchRegularCard,
    currentAllowedSeq,
    completedListRef,
  };
  return <ListContext.Provider value={value}>{children}</ListContext.Provider>;
}

export default ListContextProvider;
