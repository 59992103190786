import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import "react-circular-progressbar/dist/styles.css";
import moment from "moment";
import PriorityListDropdown from "./dropdown/PriorityListDropdown";
import TagsDropdown from "./dropdown/TagsDropdown";

const Card = ({ item, listId, cursor, tags, setTags }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: item.id,
    data: {
      type: item.type,
      item: item,
      parent: listId,
    },
  });
  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
    cursor: cursor && cursor,
    minWidth: "270px",
    maxWidth: "270px",
  };

  return (
    <>
      {item?.value && (
        <div
          className="card mb-3 mx-1 bg-light rounded-1"
          style={style}
          {...attributes}
          {...listeners}
          ref={setNodeRef}
        >
          <div
            className={`card-body rounded-1 px-3 py-0 position-relative ${
              isDragging ? "opacity-50" : ""
            } `}
            style={{
              boxShadow: isDragging
                ? "rgba(0, 0, 0, 0.3) 2.4px 2.4px 3.2px inset, rgba(255, 255, 255, 0.3) -2.4px -2.4px 3.2px inset"
                : "",
              border: cursor === "move" ? "1px solid gray" : "none",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
              }}
            >
              <div
                className="mt-3"
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "8px",
                  marginRight: "20px",
                }}
              >
                <p
                  style={{
                    cursor: "pointer",
                    fontSize: "1em",
                  }}
                >
                  {item.value}
                </p>
              </div>
            </div>
            <div className="ps-1 pt-1 row">
              <div className="col-12 ps-3 mb-2 text-start d-flex align-items-center">
                <div className="me-2 my-0">
                  <PriorityListDropdown listId={listId} item={item} />
                </div>
                <p className="me-2 my-0">
                  <span
                    className="badge bg-warning"
                    style={{ fontSize: "10px" }}
                  >
                    <span className="px-2">
                      {moment(item?.date, "YYYY-MM-DD").format("DD-MM-YYYY")}
                    </span>
                  </span>
                </p>
                <div className="me-2 my-0" style={{ display: "inline-block" }}>
                  <TagsDropdown
                    listId={listId}
                    item={item}
                    color="gray"
                    tags={tags}
                    setTags={setTags}
                  />
                </div>
              </div>
            </div>
          </div>
          <span
            className="badge rounded position-absolute top-0 end-0 mt-3 me-3 text-wrap"
            style={{
              color: "white",
              backgroundColor:
                item.card_type === "Project Card"
                  ? "purple"
                  : item.card_type === "Regular"
                  ? "skyblue"
                  : item.card_type === "Structural Card"
                  ? "pink"
                  : item.card_type === "Meeting Card"
                  ? "#06D001"
                  : "tomato",
              fontSize: ".7em",
            }}
          >
            {item.card_type === "wrokflowblockcard"
              ? "WC"
              : item.card_type === "Regular"
              ? "RC"
              : item.card_type === "Structural Card"
              ? "SC"
              : item.card_type === "Project Card"
              ? "PC"
              : item.card_type === "Meeting Card"
              ? "MC"
              : "TC"}
          </span>
        </div>
      )}
    </>
  );
};

export default Card;
