import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import axios from "axios";
import { VARIABLES } from "../../Constants";
import secureLocalStorage from "react-secure-storage";
import { DropdownAPIsContext } from "../DropdownAPIsContext/DropdownAPIsContextProvider";
import { UsersContext } from "../UsersContext/UsersContextProvider";

export const ProjectContext = createContext(null);

function ProjectContextProvider({ children }) {
  const [projectData, setProjectData] = useState([]);
  const [departmentData, setDepartmentData] = useState([]);
  const [allProjects, setAllProjects] = useState([]);
  const [projectByUser, setProjectByUser] = useState([]);
  const [categoryFilter, setCategoryFilter] = useState(null);
  const [noPages, setNoPages] = useState(1);
  const [entire, setEntries] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [noPagesDoc, setNoPagesDoc] = useState(1);
  const [entireDoc, setEntriesDoc] = useState(10);
  const [currentPageDoc, setCurrentPageDoc] = useState(1);
  const [isProjectLoading, setIsProjectLoading] = useState(false);
  const [isDepartmentsLoading, setIsDepartmentsLoading] = useState(false);
  const [usersProjectsLoading, setUsersProjectsLoading] = useState(false);
  const userID = secureLocalStorage.getItem("userID");
  const [showAllProjects, setShowAllProjects] = useState(false);
  const [showAllDepartments, setShowAllDepartments] = useState(false);
  const { op, st } = useContext(DropdownAPIsContext);
  const { isUserLoggedIn } = useContext(UsersContext);
  const cancelTokenSource = useRef(null);

  const apiGetProjects = async (page_no = currentPage, entires = entire) => {
    if (cancelTokenSource.current) {
      cancelTokenSource.current.cancel(
        "Operation canceled due to new request."
      );
    }
    cancelTokenSource.current = axios.CancelToken.source();
    setIsProjectLoading(true);
    try {
      const res = await axios.get(
        VARIABLES.url +
          `/api-app/project?page_no=${page_no}&entries=${entires}&category_ids=${categoryFilter.toString()}&user_id=${userID}&show_all=${
            showAllProjects ? "True" : "False"
          }`,
        {
          cancelToken: cancelTokenSource.current.token,
        }
      );
      setNoPages(res.data.num_pages);
      setProjectData(res.data.results.data);
      setCurrentPage(res.data.current_page);
      setIsProjectLoading(false);
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("Request canceled", error.message);
      } else {
        console.error("Error occurred: ", error);
        setIsProjectLoading(false);
      }
    }
  };
  const apiGetDepartments = async (
    page_no = currentPageDoc,
    entires = entireDoc
  ) => {
    setIsDepartmentsLoading(true);
    try {
      const res = await axios.get(
        VARIABLES.url +
          `/api-app/project?page_no=${page_no}&entries=${entires}&category_ids=${st}&user_id=${userID}&show_all=${
            showAllDepartments ? "True" : "False"
          }`
      );
      setNoPagesDoc(res.data.num_pages);
      setDepartmentData(res.data.results.data);
      setCurrentPageDoc(res.data.current_page);
      setIsDepartmentsLoading(false);
    } catch (error) {
      setIsDepartmentsLoading(false);
    }
  };
  const resetProjectByUser = () => {
    setProjectByUser([]);
  };
  const apiGetProjectByUser = async (userId) => {
    if (userId) {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${VARIABLES.url}/api-app/project?user_id=${userId}&show_all=False&category_ids=${op}`,
        headers: {},
      };
      resetProjectByUser();
      setUsersProjectsLoading(true);
      axios
        .request(config)
        .then((response) => {
          setProjectByUser(
            response.data.results.data.map((p) => ({
              value: p.id,
              label: p.project_name,
            }))
          );
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setUsersProjectsLoading(false);
        });
    }
  };

  useEffect(() => {
    if (isUserLoggedIn && categoryFilter !== null) {
      apiGetProjects();
    } else {
      setIsProjectLoading(true);
    }
  }, [isUserLoggedIn, categoryFilter, showAllProjects]);

  useEffect(() => {
    if (st) {
      apiGetDepartments(1, 10);
    }
  }, [showAllDepartments, st]);
  useEffect(() => {
    if (op) {
      setCategoryFilter([op]);
    }
  }, [op]);

  const value = {
    projectData,
    departmentData,
    allProjects,
    isProjectLoading,
    isDepartmentsLoading,
    // apiGetRawProjectData,
    setCategoryFilter,
    categoryFilter,
    apiGetProjects,
    apiGetDepartments,
    setIsProjectLoading,
    noPages,
    currentPage,
    entire,
    setEntries,
    setCurrentPage,
    showAllProjects,
    setShowAllProjects,
    showAllDepartments,
    setShowAllDepartments,
    apiGetProjectByUser,
    projectByUser,
    usersProjectsLoading,
    resetProjectByUser,
    noPagesDoc,
    currentPageDoc,
    entireDoc,
    setEntriesDoc,
    setCurrentPageDoc,
  };
  return (
    <ProjectContext.Provider value={value}>{children}</ProjectContext.Provider>
  );
}

export default ProjectContextProvider;
