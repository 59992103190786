import React from "react";
import Board from "./board/Board";
import TaskOverlay from "./board/TaskOverlay";
import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import "./List.css";
import { ListContext } from "./ListContextProvider";
import useList from "../../hooks/list/useList";

const List = () => {
  const {
    tasks,
    handleDragStart,
    handleDragOver,
    handleDragEnd,
    toggleAccordions,
    openAccordion,
    activeKey,
    activeTask,
  } = useList();
  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      distance: 3,
    },
  });
  const touchSensor = useSensor(TouchSensor);
  const keyboardSensor = useSensor(KeyboardSensor);

  const sensors = useSensors(mouseSensor, touchSensor, keyboardSensor);

  return (
    <div className="Listview container-fluid overflow-auto d-flex align-items-start justify-content-start pt-3">
      <ListContext.Provider
        value={{
          tasks,
          toggleAccordions,
          openAccordion,
          activeKey,
          activeTask,
        }}
      >
        <DndContext
          onDragStart={handleDragStart}
          onDragOver={handleDragOver}
          onDragEnd={handleDragEnd}
          collisionDetection={closestCenter}
          sensors={sensors}
        >
          {tasks && tasks.length !== 0 ? (
            <Board tasks={tasks} />
          ) : (
            <span>No Tasks Available</span>
          )}
          <TaskOverlay draggingItem={activeTask} />
        </DndContext>
      </ListContext.Provider>
    </div>
  );
};

export default List;
