import React, { useEffect, useState, useRef } from "react";
import { createPortal } from "react-dom";
import { usePopper } from "react-popper";
import "./Dropdown.css";

const Portal = ({ children }) => {
  return createPortal(children, document.body);
};

function PriorityListDropdown({ listId, item, color }) {
  const [refrenceElement, setRefrenceElement] = useState();
  const [popperElement, setPopperElement] = useState();
  const DropdownRef = useRef(null);
  const [showDrop, setShowDrop] = useState(false);
  const [priority, setPriority] = useState(item.priority);
  let { styles, attributes } = usePopper(refrenceElement, popperElement);

  const handleOutsideClick = (e) => {
    if (DropdownRef.current && !DropdownRef.current.contains(e.target)) {
      setShowDrop(false);
    }
  };

  const getColor = () => {
    if (item.priority === "urgent") {
      return "red";
    } else if (item.priority === "high") {
      return "orange";
    } else if (item.priority === "none") {
      return "gray";
    } else if (item.priority === "low") {
      return "skyblue";
    }
    return color;
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  });

  useEffect(() => {
    setShowDrop(false);
    if (priority !== item.priority) {
      // setNewValue(listId, null, item.id);
      // setCurrentPriority(priority);
    }
  }, [priority]);

  return (
    <>
      <i
        className={`bi bi-arrow-${item.priority !== "low" ? "up" : "down"}`}
        style={{
          cursor: "pointer",
          color: getColor(),
        }}
        onClick={() => setShowDrop(true)}
        ref={setRefrenceElement}
      ></i>
      {showDrop ? (
        <Portal>
          <div
            className="dropdown-list"
            style={styles.popper}
            {...attributes.popper}
            ref={setPopperElement}
          >
            <div ref={DropdownRef}>
              <div className="list-group">
                <button
                  type="button"
                  className="list-group-item list-group-item-action border-0"
                  onClick={() => setPriority("urgent")}
                >
                  <i
                    className="bi bi-arrow-up"
                    style={{
                      color: "red",
                      fontWeight: "bolder",
                      paddingRight: "5px",
                    }}
                  ></i>
                  <span style={{ fontSize: "1em" }}>Urgent</span>
                </button>
                <button
                  type="button"
                  className="list-group-item list-group-item-action border-0"
                  onClick={() => setPriority("high")}
                >
                  <i
                    className="bi bi-arrow-up"
                    style={{
                      color: "orange",
                      fontWeight: "bolder",
                      paddingRight: "5px",
                    }}
                  ></i>
                  <span style={{ fontSize: "1em" }}>Hign</span>
                </button>
                <button
                  type="button"
                  className="list-group-item list-group-item-action border-0"
                  onClick={() => setPriority("none")}
                >
                  <i
                    className="bi bi-arrow-up"
                    style={{
                      color: "gray",
                      fontWeight: "bolder",
                      paddingRight: "5px",
                    }}
                  ></i>
                  <span style={{ fontSize: "1em" }}>None</span>
                </button>
                <button
                  type="button"
                  className="list-group-item list-group-item-action border-0"
                  onClick={() => setPriority("low")}
                >
                  <i
                    className="bi bi-arrow-down"
                    style={{
                      color: "skyblue",
                      fontWeight: "bolder",
                      paddingRight: "5px",
                    }}
                  ></i>
                  <span style={{ fontSize: "1em" }}>Low</span>
                </button>
              </div>
            </div>
          </div>
        </Portal>
      ) : (
        ""
      )}
    </>
  );
}

export default PriorityListDropdown;
