import axios from "axios";
import { VARIABLES } from "../Constants";

const apiClient = axios.create({
  baseURL: VARIABLES.url,
  headers: {
    "Content-Type": "application/json",
  },
});

export default apiClient;
