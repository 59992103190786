import React, { useState, useEffect, createContext, useContext } from "react";
import secureLocalStorage from "react-secure-storage";
import { VARIABLES } from "../../Constants";
import axios from "axios";
import { UsersContext } from "../UsersContext/UsersContextProvider";

export const DropdownAPIsContext = createContext(null);

function DropdownAPIsContextProvider({ children }) {
  const { isUserLoggedIn } = useContext(UsersContext);
  const [projectByIdDropdown, setProjectByIdDropdown] = useState([]);
  const [taskByIdDropdown, setTaskByIdDropdown] = useState([]);
  const [organisations, setOrganisations] = useState([]);
  const [department, setDepartment] = useState([]);
  const [typeOfDoc, setTypeOfDoc] = useState([]);
  const [projects, setProjects] = useState([]);
  const [subcompany, setSubcompany] = useState([]);
  const [projectCategory, setProjectCategory] = useState([]);
  const [category, setCategory] = useState([]);
  const [boards, setBoards] = useState([]);
  const [levelRole, setLevelRole] = useState([]);
  const [techRole, setTechRole] = useState([]);
  const [targetTypes, setTargetTypes] = useState([]);
  const [privilege, setPrivilege] = useState([]);
  const [subproject, setSubproject] = useState([]);
  const [projectWorkflowType, setProjectWorkflowType] = useState([]);
  const [projectManagementType, setProjectManagementType] = useState([]);
  const [projectFlagStatus, setProjectFlagStatus] = useState([]);
  const [passwordType, setPasswordType] = useState([]);
  const [regularCardProjectId, setRegularCardProjectId] = useState(null);
  const [regularCardBoardId, setRegularCardBoardId] = useState(null);
  const [targetCardProjectId, setTargetCardProjectId] = useState(null);
  const [targetCardBoardId, setTargetCardBoardId] = useState(null);
  const [kanbanBoardId, setKanbanBoardId] = useState(null);
  const [meetBoardId, setMeetBoardId] = useState(null);
  const [meetProjectId, setMeetProjectId] = useState(null);
  const [ticketType, setTicketType] = useState([]);
  const [ticketStatus, setTicketStatus] = useState([]);
  const [ticketApp, setTicketApp] = useState([]);
  const [op, setOP] = useState(null);
  const [st, setST] = useState(null);
  const [generic, setGeneric] = useState(null);
  const [loadingGenericById, setLoadingGenericById] = useState(false);
  const [loadingGeneric, setLoadingGeneric] = useState(false);
  const [lodingTypemaster, setLoadingTypemaster] = useState(false);
  const [nocache, setNocache] = useState(null);
  const [ticketClosed, setTicketClosed] = useState(null);
  const [ticketOpen, setTicketOpen] = useState(null);
  const [ticketSolved, setTicketSolved] = useState(null);
  const userID = secureLocalStorage.getItem("userID");
  const getGenericDropdownById = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${VARIABLES.url}/api-app/generic-dropdown-by-id/${userID}`,
      headers: {},
    };
    setLoadingGenericById(true);
    axios
      .request(config)
      .then((response) => {
        setProjectByIdDropdown(response.data.data.project);
        setTaskByIdDropdown(response.data.data.timesheetcard);
        setBoards(response.data.data.board);
        setLoadingGenericById(false);
      })
      .catch((error) => {
        console.log(error);
        setLoadingGenericById(false);
      });
  };
  const getGenericDropdown = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${VARIABLES.url}/api-app/generic-dropdown`,
      headers: {},
    };
    setLoadingGeneric(true);
    axios
      .request(config)
      .then((response) => {
        setOrganisations(response.data.data.organisations);
        setProjects(response.data.data.projects);
        setSubcompany(response.data.data.subcompany);
        setLevelRole(response.data.data.level_role);
        setTechRole(response.data.data.technology_role);
        setPrivilege(response.data.data.privilege);
        setSubproject(response.data.data.subproject);
        setDepartment(response.data.data.department);
        setTypeOfDoc(response.data.data.typeofdocument);
        setProjectCategory(response.data.data.projectcategory);
        setCategory(response.data.data.category);
        setOP(
          response.data.data.projectcategory.filter((c) => c.name === "OP")[0]
            .id
        );
        setST(
          response.data.data.projectcategory.filter((c) => c.name === "ST")[0]
            .id
        );
        setGeneric(
          response.data.data.projectcategory.filter(
            (c) => c.name === "Generic"
          )[0].id
        );
        setLoadingGeneric(false);
      })
      .catch((error) => {
        console.log(error);
        setLoadingGeneric(false);
      });
  };
  const getTypemasterDropdown = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${VARIABLES.url}/api-app/typemaster-dropdown`,
      headers: {},
    };
    setLoadingTypemaster(true);
    axios
      .request(config)
      .then((response) => {
        setProjectWorkflowType(response.data.data.ProjectWorkflowType);
        setProjectManagementType(response.data.data.ProjectManagementType);
        setProjectFlagStatus(response.data.data.ProjectFlagStatus);
        setPasswordType(response.data.data.PasswordType);
        setLoadingTypemaster(false);
      })
      .catch((error) => {
        console.log(error);
        setLoadingTypemaster(false);
      });
  };
  const getManualId = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${VARIABLES.url}/api-app/typemaster?type=Manual Id`,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        setRegularCardProjectId(
          response.data.filter(
            (obj) => obj.name === "regular card project id"
          )[0].value
        );
        setTargetCardProjectId(
          response.data.filter(
            (obj) => obj.name === "target card project id"
          )[0].value
        );
        setRegularCardBoardId(
          response.data.filter((obj) => obj.name === "regular card board id")[0]
            .value
        );
        setTargetCardBoardId(
          response.data.filter((obj) => obj.name === "target card board id")[0]
            .value
        );
        setKanbanBoardId(
          response.data.filter((obj) => obj.name === "kanban board board id")[0]
            .value
        );
        setMeetBoardId(
          response.data.filter((obj) => obj.name === "meeting board id")[0]
            .value
        );
        setMeetProjectId(
          response.data.filter((obj) => obj.name === "meeting project id")[0]
            .value
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getTargetTypes = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${VARIABLES.url}/api-app/typemaster?type=Target Type`,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        setTargetTypes(
          response?.data?.map((type) => ({
            value: type.value,
            label: type.name,
          }))
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const apiGetTicketType = async () => {
    try {
      const res = await axios.get(
        VARIABLES.url + "/api-app/typemaster?type=Ticket Type"
      );
      setTicketType(
        res.data.map((data) => ({
          value: data.name,
          label: data.name,
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };
  const apiGetTicketStatus = async () => {
    try {
      const res = await axios.get(
        VARIABLES.url + "/api-app/typemaster?type=Ticket Status"
      );
      setTicketStatus(
        res.data.map((data) => ({
          value: data.value,
          label: data.name,
        }))
      );
      setTicketOpen(res.data.filter((data) => data.name === "open")[0].value);
      setTicketSolved(
        res.data.filter((data) => data.name === "Solved")[0].value
      );
      setTicketClosed(
        res.data.filter((data) => data.name === "Closed")[0].value
      );
    } catch (error) {
      console.log(error);
    }
  };
  const apiGetTicketApplication = async () => {
    try {
      const res = await axios.get(
        VARIABLES.url + "/api-app/typemaster?type=Ticket_Application"
      );
      setTicketApp(
        res.data.map((data) => ({
          value: data.name,
          label: data.name,
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (isUserLoggedIn) {
      getGenericDropdownById();
    }
  }, [isUserLoggedIn]);
  useEffect(() => {
    getGenericDropdown();
    getTypemasterDropdown();
    getManualId();
    getTargetTypes();
    apiGetTicketType();
    apiGetTicketStatus();
    apiGetTicketApplication();
    setNocache(new Date().getTime());
  }, []);
  const value = {
    projectByIdDropdown,
    taskByIdDropdown,
    organisations,
    department,
    typeOfDoc,
    projects,
    subcompany,
    projectCategory,
    category,
    boards,
    levelRole,
    techRole,
    privilege,
    subproject,
    projectWorkflowType,
    projectManagementType,
    projectFlagStatus,
    passwordType,
    regularCardProjectId,
    regularCardBoardId,
    targetCardProjectId,
    targetCardBoardId,
    kanbanBoardId,
    meetBoardId,
    meetProjectId,
    loadingGeneric,
    loadingGenericById,
    lodingTypemaster,
    op,
    st,
    generic,
    nocache,
    setNocache,
    targetTypes,
    ticketType,
    ticketStatus,
    ticketApp,
    ticketOpen,
    ticketClosed,
    ticketSolved,
    getGenericDropdown,
  };
  return (
    <DropdownAPIsContext.Provider value={value}>
      {children}
    </DropdownAPIsContext.Provider>
  );
}

export default DropdownAPIsContextProvider;
