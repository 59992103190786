import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import axios from "axios";
import { closeSnackbar, enqueueSnackbar } from "notistack";
import moment from "moment";
import { pushNotification } from "../../utils/notification";
import { VARIABLES } from "../../Constants";
import secureLocalStorage from "react-secure-storage";
import { UsersContext } from "../UsersContext/UsersContextProvider";

export const NotificationsContext = createContext(null);

// let VARIABLES = { url: "https://sse.techwalnut.co.in" };

function NotificationsContextProvider({ children }) {
  // const [userId, setUserId] = useState(68);
  const { isUserLoggedIn } = useContext(UsersContext);
  const userId = secureLocalStorage.getItem("userID");
  const [notifications, setNotifications] = useState([]);
  const [formatedNotifications, setFormatedNotifications] = useState([]);
  const [allNotifications, setAllNotifications] = useState([]);
  const [readNotifications, setReadNotifications] = useState([]);
  const [unReadNotifications, setUnReadNotifications] = useState([]);
  const [unreadNotificationsCount, setUnreadNotificationsCount] = useState(0);
  const [retryCount, setRetryCount] = useState(0);
  const eventSourceRef = useRef(null);
  const [noPages, setNoPages] = useState(1);
  const [entries, setEntries] = useState(10);
  const [totalEntries, setTotalEntries] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [areNotificationsLoading, setAreNotificationsLoading] = useState(false);
  const [show, setShow] = useState(false);

  const getNotifications = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${VARIABLES.url}/notifications/notifications/?is_read=false&user_id=${userId}`,
      headers: {},
    };
    axios
      .request(config)
      .then((response) => {
        setNotifications(response.data.results.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getAllNotifications = (page_no = currentPage, entrie = entries) => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url:
        VARIABLES.url +
        `/notifications/notifications/?page_no=${page_no}&entries=${entrie}&user_id=${userId}`,
      headers: {},
    };
    setAreNotificationsLoading(true);
    axios
      .request(config)
      .then((response) => {
        setNoPages(response.data.num_pages);
        setCurrentPage(response.data.current_page);
        setTotalEntries(response.data.count);
        setAllNotifications(response.data.results.data);
        setAreNotificationsLoading(false);
      })
      .catch((error) => {
        setAreNotificationsLoading(false);
        console.log(error);
      });
  };

  const handleNotificationStatus = (id) => {
    setNotifications(
      notifications.map((notification) => {
        if (notification.id === id) return { ...notification, is_read: true };
        return notification;
      })
    );
    if (!readNotifications.includes(id))
      setReadNotifications([...readNotifications, id]);
  };

  const updateNotificationStatus = () => {
    if (unReadNotifications.length !== 0 || readNotifications.length !== 0) {
      let data = "";
      data = JSON.stringify({
        mark_as_read: readNotifications,
        mark_as_unread: unReadNotifications,
      });
      if (data !== "") {
        let config = {
          method: "patch",
          maxBodyLength: Infinity,
          url: `${VARIABLES.url}/notifications/notifications/`,
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };
        axios
          .request(config)
          .then((response) => {
            getNotifications();
            getAllNotifications();
            setUnReadNotifications([]);
            setReadNotifications([]);
            enqueueSnackbar("Notifications status update!", {
              autoHideDuration: 3000,
              anchorOrigin: { horizontal: "right", vertical: "bottom" },
              variant: "success",
            });
          })
          .catch((error) => {
            console.log(error);
          });
      }
    } else {
      enqueueSnackbar("Nothing to update!", {
        autoHideDuration: 3000,
        anchorOrigin: { horizontal: "right", vertical: "bottom" },
        variant: "error",
      });
    }
  };

  const createConnection = () => {
    if (eventSourceRef.current) {
      eventSourceRef.current.close();
    }

    const sse = new EventSource(
      `${VARIABLES.url}/notifications/?user_id=${userId}`,
      {
        withCredentials: false,
      }
    );

    setRetryCount(0);

    sse.onmessage = (e) => {
      try {
        const data = JSON.parse(e.data);
        pushNotification({
          title: data[0].title,
          body: data[0].message,
          image:
            "https://tw-beaver-builder.s3.ap-south-1.amazonaws.com/static/assets/images/application_logos/nipun.png",
        });
        setNotifications(
          data.map((e) => {
            return {
              ...e,
              is_read: false,
            };
          })
        );
        getAllNotifications();
      } catch (error) {
        console.error("Error parsing SSE data:", error);
      }
    };
    sse.onerror = () => {
      console.error("Connection error");
      sse.close();
      setRetryCount((prevCount) => prevCount + 1);
      setTimeout(createConnection, 15000);
    };
    eventSourceRef.current = sse;
  };

  useEffect(() => {
    setUnreadNotificationsCount(notifications.length);
  }, [notifications]);

  useEffect(() => {
    if (show && notifications.length !== 0) {
      setFormatedNotifications(
        notifications.map((e) => ({
          ...e,
          created_at: moment(e.created_at).fromNow(),
        }))
      );
    }
  }, [show, notifications]);

  useEffect(() => {
    if (isUserLoggedIn) {
      createConnection();
      return () => {
        if (eventSourceRef.current) {
          eventSourceRef.current.close();
        }
      };
    }
  }, [isUserLoggedIn]);

  useEffect(() => {
    if (isUserLoggedIn) {
      getNotifications();
      getAllNotifications();
    }
  }, [isUserLoggedIn]);

  const value = {
    notifications,
    formatedNotifications,
    allNotifications,
    unreadNotificationsCount,
    setNotifications,
    areNotificationsLoading,
    noPages,
    currentPage,
    entries,
    setEntries,
    setCurrentPage,
    handleNotificationStatus,
    updateNotificationStatus,
    getAllNotifications,
    totalEntries,
    readNotifications,
    unReadNotifications,
    setReadNotifications,
    setUnReadNotifications,
    setAllNotifications,
    show,
    setShow,
  };

  return (
    <NotificationsContext.Provider value={value}>
      {children}
    </NotificationsContext.Provider>
  );
}

export default NotificationsContextProvider;
