import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { VARIABLES } from "../../../Constants";
import axios from "axios";
import secureLocalStorage from "react-secure-storage";
import { DropdownAPIsContext } from "../../../context/DropdownAPIsContext/DropdownAPIsContextProvider";
import { useQueryClient } from "react-query";

const AddActions = ({ currentItemId, isOpen }) => {
  const [cards, setCards] = useState([]);
  const [loading, setLoading] = useState(false);
  const userID = secureLocalStorage.getItem("userID");
  const { regularCardBoardId, regularCardProjectId } =
    useContext(DropdownAPIsContext);
  const queryClient = useQueryClient();
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const getRegularCards = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${VARIABLES.url}/api-app/meetingcard/${currentItemId?.card_id_id}`,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        setCards(response.data.regular_cards);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onSubmit = (data) => {
    const res = {
      created_by_id: userID,
      updated_by_id: userID,
      card_title: data.action,
      start_time: currentItemId?.startDate,
      end_time: currentItemId?.endDate,
      card_description: currentItemId?.description,
      org_created_by_id: 2,
      // To-Do: change regular card board id manually
      board_id_id: regularCardBoardId,
      assigned_to_id: currentItemId?.assigned_to,
      current_status: "Assigned",
      meeting_id: currentItemId?.card_id_id,
    };
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${VARIABLES.url}/api-app/regular-card`,
      headers: {
        "Content-Type": "application/json",
      },
      data: res,
    };
    setLoading(true);
    axios
      .request(config)
      .then((response) => {
        getRegularCards();
        queryClient.refetchQueries([
          "boards-tasks",
          parseInt(regularCardProjectId),
          userID,
        ]);
        reset({ action: "" });
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (!isOpen) reset({ action: "" });
  }, [isOpen]);

  useEffect(() => {
    getRegularCards();
  }, [currentItemId]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="px-3 pb-3">
      {/* <div className="row">
        <div className="col-12 mt-2">
          <input className="DrawerInput" disabled />
        </div>
      </div> */}
      <div className="row">
        <div className="col-12 mb-3">
          <label className="form-label">
            Action <span className="required_field ">*</span>
          </label>
          {cards.map((card) => {
            return (
              <input
                className="DrawerInput mb-2"
                placeholder={`${card.card_title}`}
                disabled
              />
            );
          })}

          <input
            id="action"
            name="action"
            className="DrawerInput"
            {...register("action", {
              required: true,
            })}
            placeholder="Action..."
          />
          {errors.action && (
            <p className="required_field error">Field cannot be empty</p>
          )}
        </div>
        <div className="col-12">
          <button type="submit" className="btn btn-primary" disabled={loading}>
            ADD
          </button>
        </div>
      </div>
    </form>
  );
};

export default AddActions;
