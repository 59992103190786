import React from "react";
import Board from "./board/Board";
import List from "./board/List";
import Card from "./board/Card";
import useKanban from "../../hooks/kanban/useKanban";
import DraggingCard from "./board/DraggingCard";
import "./KanbanTest.css";

const Kanban = () => {
  const {
    list,
    tags,
    setTags,
    draggingItem,
    handleDragStart,
    handleDragOver,
    handleDragEnd,
  } = useKanban();

  return (
    <div className="BoardTest board-height container-fluid overflow-auto d-flex align-items-start justify-content-start pt-3">
      <Board
        list={list}
        handleDragOver={handleDragOver}
        handleDragEnd={handleDragEnd}
        handleDragStart={handleDragStart}
      >
        {list.map((listItem) => {
          return (
            <List key={listItem.id} listItem={listItem}>
              {listItem.items.map((item) => (
                <Card
                  key={item.id}
                  item={item}
                  listId={listItem.id}
                  tags={tags}
                  setTags={setTags}
                />
              ))}
            </List>
          );
        })}
        <DraggingCard draggingItem={draggingItem} list={list} />
      </Board>
    </div>
  );
};

export default Kanban;
