import React, { useEffect, useState, useRef } from "react";
import { createPortal } from "react-dom";
import { usePopper } from "react-popper";
import "./Dropdown.css";
import CreatableSelect from "react-select/creatable";

const Portal = ({ children }) => {
  return createPortal(children, document.body);
};

function TagsDropdown({ listId, item, color, tags, setTags }) {
  const [refrenceElement, setRefrenceElement] = useState();
  const [popperElement, setPopperElement] = useState();
  const DropdownRef = useRef(null);
  const [showDrop, setShowDrop] = useState(false);
  // const [tags, setTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  let { styles, attributes } = usePopper(refrenceElement, popperElement);

  const handleOutsideClick = (e) => {
    if (DropdownRef.current && !DropdownRef.current.contains(e.target)) {
      setShowDrop(false);
    }
  };

  const handleChange = (selectedOption) => {
    setSelectedTags(selectedOption);
  };

  const handleCreateTag = (inputValue) => {
    const newTag = { value: inputValue.toLowerCase(), label: inputValue };
    setTags((prev) => [...prev, newTag]);
    setSelectedTags((prev) => [...prev, newTag]);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  });

  useEffect(() => {
    if (item.tags !== null) {
      setSelectedTags(
        item?.tags?.map((tag) => ({ value: tag?.id, label: tag?.name }))
      );
    }
  }, [item.tags]);

  return (
    <>
      <div
        style={{
          cursor: "pointer",
          color: color,
        }}
        onClick={() => setShowDrop(true)}
        ref={setRefrenceElement}
        className="d-flex align-items-center"
      >
        <i className="bi bi-tag-fill"></i>
      </div>

      {showDrop ? (
        <Portal>
          <div
            className="dropdown-list"
            style={styles.popper}
            {...attributes.popper}
            ref={setPopperElement}
          >
            <div ref={DropdownRef}>
              <div
                style={{
                  backgroundColor: "white",
                  borderRadius: "3px",
                  maxWidth: "300px",
                }}
              >
                <CreatableSelect
                  isMulti
                  value={selectedTags}
                  options={tags}
                  onChange={handleChange}
                  onCreateOption={handleCreateTag}
                  placeholder="Select or create tags"
                />
              </div>
            </div>
          </div>
        </Portal>
      ) : (
        ""
      )}
    </>
  );
}

export default TagsDropdown;
