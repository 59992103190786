import { useQuery } from "react-query";
import { fetchBoardsSettings } from "../../api/endpoints/task-management/boardsSettings";

const useFetchBoardsSettings = (projectId) => {
  return useQuery(
    ["boards-settings", projectId],
    () => fetchBoardsSettings({ project_id: projectId }),
    {
      enabled: !!projectId,
      staleTime: Infinity, // Data will never go stale
      cacheTime: Infinity, // Data will be cached indefinitely
      refetchOnWindowFocus: false, // Do not refetch when window is focused
      refetchOnMount: false, // Do not refetch when component mounts
      refetchOnReconnect: false, // Do not refetch when reconnecting to the internet
    }
  );
};

export default useFetchBoardsSettings;
