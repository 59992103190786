import axios from "axios";

const client = axios.create({
  baseURL: "",
  headers: {
    "Content-Type": "application/json",
  },
});

export const fetchBoard = (params = {}) => {
  return client.get("/db/data.json", { params });
};
