import React, { useContext, useEffect, useState } from "react";
import Board from "./kanban/Board";
import { TaskManagementContext } from "../../context/TaskManagementContext/TaskManagementContextProvider";
import secureLocalStorage from "react-secure-storage";
import CardDrawer from "./kanban/CardDrawer";
import AddCard from "./kanban/AddCard";
import useFetchBoardsSettings from "../../hooks/task-management/useFetchBoardsSettings";
import useFetchTasksForBoard from "../../hooks/task-management/useFetchTasksForBoard";
import useFetchCurrentBoard from "../../hooks/task-management/useFetchCurrentBoard";
import { useLocation } from "react-router-dom";

function TaskBoard() {
  const {
    currentBoardId,
    setBoardSettings,
    setLifecycle,
    setCurrentAllowedSeq,
    setTasksList,
    setCurrentBoardId,
  } = useContext(TaskManagementContext);
  const [isOpen, setIsOpen] = useState(false);
  const privileges = secureLocalStorage.getItem("privileges");
  const userID = secureLocalStorage.getItem("userID");
  const location = useLocation();
  const {
    data: settings,
    error: settingsError,
    isLoading: isSettingLoading,
  } = useFetchBoardsSettings(currentBoardId?.id);

  const {
    data: task,
    error: taskError,
    isLoading: isTaskLoading,
  } = useFetchTasksForBoard(currentBoardId?.id, userID);

  const {
    data: currentBoard,
    error: currentBoardError,
    isLoading: isCurrentBoardLoading,
  } = useFetchCurrentBoard(location.pathname.split("_").pop());

  useEffect(() => {
    if (settings && !settingsError && !isSettingLoading) {
      setBoardSettings(settings.data);
      setLifecycle(
        settings.data.lifecycle_states.sort((a, b) => a.sequence - b.sequence)
      );
      setCurrentAllowedSeq(settings.data.sequence_schema);
    }
  }, [settings, settingsError, isSettingLoading]);

  useEffect(() => {
    if (task && !taskError && !isTaskLoading) {
      setTasksList(task.data);
    }
  }, [task, taskError, isTaskLoading]);

  useEffect(() => {
    if (currentBoard && !currentBoardError && !isCurrentBoardLoading) {
      setCurrentBoardId(currentBoard.data);
    }
  }, [currentBoard, currentBoardError, isCurrentBoardLoading]);

  return (
    <>
      <div className="row align-items-center">
        <div className="border-0 mb-4">
          <div className="card-header p-0 no-bg bg-transparent d-flex align-items-center px-0 justify-content-start border-bottom flex-wrap">
            <h5 className="fw-bold py-3 mb-0">
              {currentBoardId?.project_name}
            </h5>
            <span>
              {(isSettingLoading || isTaskLoading || isCurrentBoardLoading) && (
                <div className="spinner-border text-primary ms-3" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              )}
            </span>
            <div className="ms-auto d-flex">
              {privileges.includes("create_structural_card") &&
              currentBoardId?.category?.name === "ST" ? (
                <>
                  <div className="ms-auto">
                    <button
                      type="button"
                      className="btn btn-dark w-sm-100"
                      onClick={() => setIsOpen(!isOpen)}
                    >
                      <i className="icofont-plus-circle me-2 fs-6"></i>Create
                      Structural Card
                    </button>
                  </div>
                </>
              ) : (
                ""
              )}
              {privileges.includes("create_meeting_card") &&
              currentBoardId?.project_name === "Meet" ? (
                <>
                  <div className="ms-auto">
                    <button
                      type="button"
                      className="btn btn-dark w-sm-100"
                      onClick={() => setIsOpen(!isOpen)}
                    >
                      <i className="icofont-plus-circle me-2 fs-6"></i>Create
                      Meeting Card
                    </button>
                  </div>
                </>
              ) : (
                ""
              )}
              {/* {privileges.includes("create_regular_card") &&
              currentBoardId?.project_name === "Regular Card" ? (
                <>
                  <div className="ms-auto">
                    <button
                      type="button"
                      className="btn btn-dark w-sm-100"
                      onClick={() => setIsOpen(!isOpen)}
                    >
                      <i className="icofont-plus-circle me-2 fs-6"></i>
                      Create Card
                    </button>
                  </div>
                </>
              ) : (
                ""
              )} */}
            </div>
          </div>
        </div>
      </div>
      <Board />
      <CardDrawer />
      <AddCard isOpen={isOpen} setIsOpen={setIsOpen} />
    </>
  );
}

export default TaskBoard;
