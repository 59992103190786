import React, { createContext, useContext, useEffect, useState } from "react";
import axios from "axios";
import { VARIABLES } from "../../Constants";
import secureLocalStorage from "react-secure-storage";
import Swal from "sweetalert2";
import { UsersContext } from "../UsersContext/UsersContextProvider";
import { useLocation } from "react-router-dom";

export const LeaveManagementContext = createContext(null);

function LeaveManagementProvider({ children }) {
  const userID = secureLocalStorage.getItem("userID");
  const { isUserLoggedIn } = useContext(UsersContext);
  const [rawLeaveData, setRawLeaveData] = useState([]);
  const [rawLeaveDataAll, setRawLeaveDataAll] = useState([]);
  const [leaveType, setLeaveType] = useState([]);
  const [employeeData, setEmployeeData] = useState([]);
  const [holidays, setHolidays] = useState([]);
  const [totalEmployeeLeaveData, setTotalEmployeeLeaveData] = useState([]);
  const [totalLeaveAvaliable, setTotalLeaveAvaliable] = useState([]);
  const [loadingCount, setLoadingCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const { users } = useContext(UsersContext);
  const [noPages, setNoPages] = useState(1);
  const [entire, setEntries] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [noPagesLeaveAva, setNoPagesLeaveAva] = useState(1);
  const [entireLeaveAva, setEntriesLeaveAva] = useState(10);
  const [currentPageLeaveAva, setCurrentPageLeaveAva] = useState(1);
  const [loadingFlag, setLoadingFlag] = useState(false);

  const location = useLocation();

  const apiGetLeaveDataAll = async (
    page_no = currentPage,
    entires = entire
  ) => {
    try {
      const res = await axios.get(
        VARIABLES.url +
          `/api-app/leave-management?page_no=${page_no}&entries=${entires}`
      );
      setNoPages(res.data.num_pages);
      setCurrentPage(res.data.current_page);
      setRawLeaveDataAll(res.data.results.data);
      setLoadingCount((count) => (count += 1));
    } catch (error) {
      console.log(error);
    }
  };

  const apiGetLeaveData = async () => {
    try {
      const res = await axios.get(
        VARIABLES.url +
          "/api-app/leave-management?employee_id=" +
          parseInt(userID)
      );
      setRawLeaveData(res.data.data);
      setLoadingCount((count) => (count += 1));
    } catch (error) {
      console.log(error);
    }
  };

  // API call of Project Data and stores the data in "rawProjectData"
  const apiLeaveType = async () => {
    try {
      const res = await axios.get(VARIABLES.url + "/api-app/leave-types");
      setLeaveType(
        res.data.map((userData) => ({
          value: userData.id,
          label: userData.name,
        }))
      );
      setLoadingCount((count) => (count += 1));
    } catch (error) {
      console.log(error);
    }
  };

  const apiHolidayData = async () => {
    try {
      const res = await axios.get(VARIABLES.url + "/api-app/holidays");
      setHolidays(res.data);
      setLoadingCount((count) => (count += 1));
    } catch (error) {
      console.log(error);
    }
  };

  const apiTotalEmployeeLeaveData = async (
    page_no = currentPage,
    entires = entire
  ) => {
    try {
      const res = await axios.get(
        VARIABLES.url +
          `/api-app/leave-available?page_no=${page_no}&entries=${entires}`
      );
      setNoPagesLeaveAva(res.data.num_pages);
      setCurrentPageLeaveAva(res.data.current_page);
      setTotalEmployeeLeaveData(res.data.results.data);
      setLoadingCount((count) => (count += 1));
    } catch (error) {
      console.log(error);
    }
  };

  const apiTotalLeave = async () => {
    try {
      const res = await axios.get(
        VARIABLES.url +
          "/api-app/leave-available?employee_id=" +
          parseInt(userID)
      );
      setTotalLeaveAvaliable(res.data.data[0]);
      setLoadingCount((count) => (count += 1));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (users.length !== 0) {
      setEmployeeData(users);
      setLoadingCount((count) => (count += 1));
    }
  }, [users]);

  useEffect(() => {
    if (isUserLoggedIn === true && loadingFlag === true) {
      apiGetLeaveData();
      apiLeaveType();
      apiHolidayData();
      apiGetLeaveDataAll();
      apiTotalEmployeeLeaveData();
      apiTotalLeave();
    }
  }, [isUserLoggedIn, loadingFlag]);

  useEffect(() => {
    if (
      location.pathname === "/leave" ||
      location.pathname === "/leaveapplication" ||
      location.pathname === "/leavestatus"
    ) {
      setLoadingFlag(true);
    }
  }, [location]);

  useEffect(() => {
    if (loadingCount >= 6) {
      setIsLoading(false);
    }
  }, [loadingCount]);

  const createLeave = (data) => {
    var config = {
      method: "post",
      url: VARIABLES.url + "/api-app/leave-management",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then((response) => {
        Swal.fire({
          title: "Success!",
          text: "Leave Apply Successfully",
          icon: "success",
          confirmButtonText: "OK",
        }).then(() => apiGetLeaveData());
      })
      .catch((error) => {
        console.log(error);
        Swal.fire({
          title: "Error",
          text: "Failed to apply Leave",
          icon: "error",
          confirmButtonText: "OK",
        });
      });
  };

  const value = {
    isLoading,
    rawLeaveData,
    rawLeaveDataAll,
    totalEmployeeLeaveData,
    leaveType,
    employeeData,
    holidays,
    totalLeaveAvaliable,
    noPages,
    entire,
    currentPage,
    noPagesLeaveAva,
    entireLeaveAva,
    currentPageLeaveAva,
    setEntriesLeaveAva,
    setEntries,
    apiGetLeaveDataAll,
    apiGetLeaveData,
    apiLeaveType,
    apiHolidayData,
    apiTotalEmployeeLeaveData,
    createLeave,
  };

  return (
    <LeaveManagementContext.Provider value={value}>
      {children}
    </LeaveManagementContext.Provider>
  );
}

export default LeaveManagementProvider;
