import axios from "axios";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { VARIABLES } from "../../Constants";
import secureLocalStorage from "react-secure-storage";

const Signals = () => {
  const [signals, setSignals] = useState([]);
  const [signalsStatus, setSignalsStatus] = useState([]);
  const [signalList, setSignalList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isStatusLoading, setIsStatusLoading] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const getSignals = async () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${VARIABLES.url}/api-app/signals`,
      headers: {},
    };
    setIsLoading(true);
    axios
      .request(config)
      .then((response) => {
        setSignals(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };
  const getSignalsStatus = async () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${
        VARIABLES.url
      }/api-app/flag-signal?created_by_id=${secureLocalStorage.getItem(
        "userID"
      )}`,
      headers: {},
    };
    setIsStatusLoading(true);
    axios
      .request(config)
      .then((response) => {
        setSignalsStatus(response.data);
        setIsStatusLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsStatusLoading(false);
      });
  };
  const signalPost = async (data) => {
    let res = JSON.stringify({
      signal_id: data?.signal_id,
      created_by_id: secureLocalStorage.getItem("userID"),
      updated_by_id: secureLocalStorage.getItem("userID"),
      flag_status: true,
    });
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${VARIABLES.url}/api-app/flag-signal`,
      headers: {
        "Content-Type": "application/json",
      },
      data: res,
    };
    setIsUpdating(true);
    axios
      .request(config)
      .then((response) => {
        setIsUpdating(false);
        Swal.fire({
          title: "Success!",
          text: "Signal's status changed successfully",
          icon: "success",
          confirmButtonText: "OK",
        }).then((result) => {
          if (result.isConfirmed) {
            getSignalsStatus();
          }
        });
      })
      .catch((error) => {
        setIsUpdating(false);
        Swal.fire({
          title: "Error",
          text: "Failed to change signals status due to: " + error.message,
          icon: "error",
          confirmButtonText: "OK",
        });
        console.log(error.message);
      });
  };
  const signalPatch = async (data) => {
    console.log("patch");
    let res = JSON.stringify({
      updated_by_id: secureLocalStorage.getItem("userID"),
      flag_status: !data?.status,
    });

    let config = {
      method: "patch",
      maxBodyLength: Infinity,
      url: `${VARIABLES.url}/api-app/flag-signal/${data?.id}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: res,
    };
    setIsUpdating(true);
    axios
      .request(config)
      .then((response) => {
        setIsUpdating(false);
        Swal.fire({
          title: "Success!",
          text: "Signal's status changed successfully",
          icon: "success",
          confirmButtonText: "OK",
        }).then((result) => {
          if (result.isConfirmed) {
            getSignalsStatus();
          }
        });
      })
      .catch((error) => {
        setIsUpdating(false);
        Swal.fire({
          title: "Error",
          text: "Failed to change signals status due to: " + error.message,
          icon: "error",
          confirmButtonText: "OK",
        });
        console.log(error.message);
      });
  };
  const changeSignalStatusAlert = (data) => {
    Swal.fire({
      title: `Are you sure? You want to turn ${data?.status ? "OFF" : "ON"} ${
        data?.signal
      } signal?`,
      showDenyButton: true,
      confirmButtonText: "Yes!",
      denyButtonText: `No, cancel!`,
    }).then((result) => {
      if (result.isConfirmed) {
        raiseSignal(data);
      }
    });
  };
  const raiseSignal = (data) => {
    if (data?.first_render) {
      signalPost(data);
    } else {
      signalPatch(data);
    }
  };
  useEffect(() => {
    if (!isLoading && !isStatusLoading) {
      if (signals.length !== 0) {
        setSignalList(
          signals.map((s) => {
            return {
              id:
                signalsStatus.filter(
                  (status) => status?.signal?.id === s?.id
                )[0]?.id || null,
              signal_id: s?.id,
              signal: s?.flag_type,
              status:
                signalsStatus.filter(
                  (status) => status?.signal?.id === s?.id
                )[0]?.flag_status || false,
              first_render:
                signalsStatus.filter((status) => status?.signal?.id === s?.id)
                  .length !== 0
                  ? false
                  : true,
            };
          })
        );
      }
    }
  }, [signals, signalsStatus, isLoading, isStatusLoading]);
  useEffect(() => {
    getSignals();
    getSignalsStatus();
  }, []);
  return (
    <>
      <div className="row g-3 mb-3 row-deck mt-3">
        <div className="col-md-12">
          <div className="card mb-3" style={{ backgroundColor: "#ECF5F5" }}>
            <div className="card-body">
              <div className="table-responsive" style={{ maxHeight: "300px" }}>
                <table
                  id="myProjectTable"
                  className="table table-hover align-middle mb-0"
                  style={{ width: "100%" }}
                >
                  <thead>
                    <tr
                      className="bg-transparent"
                      style={{
                        position: "sticky",
                        top: 0,
                      }}
                    >
                      <th>Signal</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!isLoading &&
                    !isStatusLoading &&
                    signalList.length !== 0 ? (
                      signalList.map((s) => (
                        <tr>
                          <td>{s.signal}</td>
                          <td>
                            <button
                              className={`btn btn-${
                                s.status ? "danger" : "secondary"
                              }`}
                              onClick={() => changeSignalStatusAlert(s)}
                              disabled={isUpdating}
                            >
                              {!s.status ? "Raise" : "Raised"}
                            </button>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <p>
                        {isLoading || isStatusLoading ? (
                          <p>Loading...</p>
                        ) : (
                          <p>No Data</p>
                        )}
                      </p>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Signals;
