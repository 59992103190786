import React from "react";
import { DragOverlay } from "@dnd-kit/core";
import { createPortal } from "react-dom";

const TaskOverlay = ({ draggingItem }) => {
  return (
    <>
      {createPortal(
        <DragOverlay>
          <div
            style={{
              backgroundColor: "white",
              border: "1px solid black",
              width: "100%",
              height: "3em",
              padding: "0.3em 0.5em",
              margin: "0px",
              borderRadius: "0.5em",
              display: "flex",
              alignItems: "center",
              cursor: "grab",
            }}
          >
            {draggingItem?.name}
          </div>
        </DragOverlay>,
        document.body
      )}
    </>
  );
};

export default TaskOverlay;
