import React, { useContext, useEffect, useState } from "react";
import { VARIABLES } from "../../Constants";
import axios from "axios";
import secureLocalStorage from "react-secure-storage";
import Tour from "reactour";
import Header from "./Header/Header";
import { NoticeBoard } from "../Display/NoticeBoard";
import Sidebar from "./SideBar/Sidebar";
import TimeSheetDetails from "../Display/TimeSheetDetails";
import TimeSheetCards from "../Display/TimeSheetCards";
import ViewEmployee from "../Display/ViewEmployee";
import DocumentGenerate from "../Display/DocumentGenerate";
import Projects from "../Project/Projects";
import SubProject from "../SubProject/SubProject";
import NoticeBoards from "../Noticeboard/NoticeBoard";
import ResourceMapping from "../ResourceMapping/ResourceMapping";
import ResourceWeekSheet from "../ResourceMapping/ResourceWeekSheet";
import LeaveManagement from "../LeaveManagement/LeaveManagement";
import LeaveApplication from "../LeaveManagement/LeaveApplication";
import LeaveStatus from "../LeaveManagement/LeaveStatus";
import LeaveAvaliable from "../LeaveManagement/LeaveAvaliable";
import ReleaseNotes from "../ReleaseNotes/ReleaseNotes";
import DevQAReleasesList from "../ReleaseNotes/DevQAReleasesList";
import TimeSheetCard from "../TimeSheetCard/TimeSheetCard";
import ReleasesProjectProfile from "../ReleasesProjectProfile/ReleasesProjectProfile";
import ProjectWorkflow from "../Project/ProjectWorkflow";
import ProjectWorkflowBlock from "../ProjectWorkflowBlock/ProjectWorkflowBlock";
import TaskManagement from "../TaskManagement/TaskManagement";
import TargetManagement from "../TargetManagement/TargetManagement";
import KanbanBoard from "../TaskManagement/KanbanBoard";
import TicketGenerate from "../TicketGenerate/TicketGenerate";
import Password from "../Password/Password";
import UserManagement from "../UserManagement/UserManagement";
import ProjectDocument from "../Project/ProjectDocument";
import UserManagementCreate from "../UserManagement/UserManagementCreate";
import Reporting from "../Display/Reporting";
import TeamReport from "../Display/TeamReport";
import TeamReporting from "../TeamReporting/TeamReporting";
import AssignEPC from "../AssignEPC/AssignEPC";
import TechnicalRoles from "../TechnicalRoles/TechnicalRoles";
import { Route, Router, Routes, useNavigate } from "react-router-dom";
import ProjectCategories from "../ProjectCategories/ProjectCategories";
import ProjectTags from "../ProjectTags/ProjectTags";
import ProjectCardsBoard from "../ProjectCards/ProjectCardsBoard";
import ProjectReleases from "../Project/ProjectReleases";
import MissedTimesheet from "../Display/MissedTimesheet";
import ProjectBulkEdit from "../Project/ProjectBulkEdit";
import TaskBoard from "../TaskBoards/TaskBoard";
import { TaskManagementContext } from "../../context/TaskManagementContext/TaskManagementContextProvider";
import ProjectCardsListView from "../ProjectCards/ProjectCardsListView";
import ResourceSpreadsheet from "../ResourceMapping/ResourceSpreadsheet";
import PasswordEncDec from "../Password/PasswordEncDec";
import GenerateSequence from "../Password/GenerateSequence";
import GanttView from "../ProjectCards/GanttView";
import CorrectTimesheet from "../CorrectTimesheet/CorrectTimesheet";
import Departments from "../department-management/Departments";
import DepartmentDocs from "../department-management/DepartmentDocs";
import ProjectStatsTable from "../Project/ProjectStatsTable";
import hotkeys from "hotkeys-js";
import NotificationsTable from "../notification/NotificationsTable";
import ResourceDashboardTable from "../ResourceMapping/dashboard/ResourceDashboardTable";
import DashboardView from "../ResourceMapping/dashboard/DashboardView";
import TimesheetDashboardTable from "../timesheet/TimesheetDashboardTable";
import DashboardViewTimesheet from "../timesheet/dashboard/DashboardViewTimesheet";
import List from "../list/List";
import Kanban from "../kanban/Kanban";

const Base = (props) => {
  const [showSideBar, setShowSideBar] = useState(false);

  const [projectId, setProjectId] = useState(null);

  const [projectDetails, setProjectDetails] = useState(null);

  const [workflowBlockId, setWorkflowBlockId] = useState(null);

  const showMobileMenuBar = () => {
    setShowSideBar(!showSideBar);
  };

  const navigate = useNavigate();
  // Set default screen
  const [sidebar, setSidebar] = useState("NoticeBoard");
  const userID = secureLocalStorage.getItem("userID");
  const privileges = secureLocalStorage.getItem("privileges");
  const { boardsList } = useContext(TaskManagementContext);

  const [disabledButton, setDisabledButton] = useState(true);

  // State pull up from sidebar.js
  const handleSidebar = (section, id) => {
    setSidebar(section);
    setProjectId(id);
    setWorkflowBlockId(id);
  };

  const [isTourOpen, setIsTourOpen] = useState(false);
  const handleTour = () => {
    setIsTourOpen(!isTourOpen);
  };

  const onChangeSidebar = (section) => {
    handleSidebar(section);
  };

  const steps = [
    {
      selector: ".fill-timesheet",
      content: "You can fill daily timesheet",
      action: () => {
        onChangeSidebar("NoticeBoard");
      },
      // condition : privileges.includes("show core_lead") && privileges.includes("show employee"),
      condition: privileges.includes("workdesk"),
    },
    {
      action: () => {
        onChangeSidebar("TimeSheet");
      },
      selector: ".view-timesheet",
      content: "You can view your daily timesheet",
      condition: privileges.includes("view_fill_timeheet"),
    },
    {
      action: () => {
        onChangeSidebar("TimeSheet");
      },
      selector: ".view-kanban",
      content: "You can view your Kanban board",
      condition: privileges.includes("Kanban"),
    },
    {
      action: () => {
        onChangeSidebar("TimeSheet");
      },
      selector: ".raiseticket",
      content: "You can raise tickets from here",
      condition: privileges.includes("header_raised_ticket"),
    },
    {
      action: () => {
        onChangeSidebar("TimeSheet");
      },
      selector: ".noticeboard",
      content: "You can view your notices here",
      condition: true,
    },
    {
      action: () => {
        onChangeSidebar("TimeSheet");
      },
      selector: ".leavestatus",
      content: "You can view your available leaves here",
      condition: true,
    },
    {
      action: () => {
        onChangeSidebar("TimeSheet");
      },
      selector: ".sidebar",
      content: "You can navigate between different sections from here",
      condition: true,
    },
  ];

  // It filter The Tour steps according to the condition (i.e. added as privilleges)
  const filteredTourSteps = steps.filter((step) => step.condition);

  useEffect(() => {
    const handleHotkeys = (event, handler) => {
      event.preventDefault();
      switch (handler.key) {
        case "w":
          navigate("/");
          break;
        case "t":
          navigate("/timesheet");
          break;
        case "p":
          navigate("/projects");
          break;
        case "r+m":
          navigate("/resourcemapping");
          break;
        case "l":
          navigate("/leave");
          break;
        case "k":
          navigate("/kanban");
          break;
        case "d":
          navigate("/docgen");
          break;
        default:
          break;
      }
    };

    hotkeys("w, t, p, r+m, l, k, d", handleHotkeys);

    // Cleanup the hotkeys when the component is unmounted
    return () => {
      hotkeys.unbind("w");
      hotkeys.unbind("t");
      hotkeys.unbind("p");
      hotkeys.unbind("r+m");
      hotkeys.unbind("l");
      hotkeys.unbind("k");
      hotkeys.unbind("d");
    };
  }, []);

  return (
    <div id="mytask-layout" className="theme-indigo">
      <Sidebar
        showSideBar={showSideBar}
        setDisabledButton={setDisabledButton}
        handleSidebar={handleSidebar}
        privileges={privileges}
        correctTS={props.correctTS}
      />
      <div className="main">
        <Header
          disabledButton={disabledButton}
          handleTour={handleTour}
          showMobileMenuBar={showMobileMenuBar}
          onLogout={props.onLogout}
          userData={props.userData}
        />
        {/* rendering components based on sidebar selected */}
        <div className="container-xxl px-4">
          <Tour
            steps={filteredTourSteps}
            isOpen={isTourOpen}
            onClick={handleTour}
            onRequestClose={() => setIsTourOpen(false)}
          />
          {sidebar === "TimeSheetCards" && <TimeSheetCards />}
          {sidebar === "ShowProjectProfile" && (
            <ReleasesProjectProfile handleSidebar={handleSidebar} />
          )}
          <base href="/" />
          <Routes>
            {!props.correctTS ? (
              <Route
                path="/"
                element={<CorrectTimesheet setCorrectTS={props.setCorrectTS} />}
              />
            ) : (
              <Route
                path="/"
                element={
                  <NoticeBoard
                    handleSidebar={handleSidebar}
                    userData={props.userData}
                    toggleView={handleSidebar}
                  />
                }
              />
            )}
            <Route path="/notifications" element={<NotificationsTable />} />
            <Route path="/noticeboard" element={<NoticeBoards />} />
            <Route path="/kanban" element={<KanbanBoard />} />
            <Route
              path="/timesheet"
              element={<TimeSheetDetails toggleView={handleSidebar} />}
            />
            <Route path="/missedtimesheets" element={<MissedTimesheet />} />
            <Route
              path="/timesheetdashboard"
              element={<TimesheetDashboardTable />}
            />
            <Route
              path="timesheetdashboardview"
              element={<DashboardViewTimesheet />}
            />
            <Route
              path="/correcttimesheet"
              element={<CorrectTimesheet setCorrectTS={props.setCorrectTS} />}
            />
            <Route path="/addtask" element={<TimeSheetCard />} />
            <Route
              path="/docgen"
              element={
                <DocumentGenerate
                  userData={props.userData}
                  toggleView={handleSidebar}
                />
              }
            />
            <Route
              path="/projects"
              element={
                <Projects
                  projectId={projectId}
                  setProjectId={setProjectId}
                  handleSidebar={handleSidebar}
                  setProjectDetails={setProjectDetails}
                />
              }
            />
            <Route path="/subproject" element={<SubProject />} />
            <Route path="/projectstatus" element={<ProjectStatsTable />} />
            <Route path="/departmentstatus" element={<ProjectStatsTable />} />
            <Route path="/projectspreadsheet" element={<ProjectBulkEdit />} />
            <Route path="/projectreleases" element={<ProjectReleases />} />
            <Route path="/projectcategories" element={<ProjectCategories />} />
            <Route path="/projecttags" element={<ProjectTags />} />
            <Route path="/projectcards" element={<ProjectCardsBoard />} />
            <Route path="/ganttview" element={<GanttView />} />
            <Route
              path="/projectcardslistview"
              element={<ProjectCardsListView />}
            />
            <Route path="/departments" element={<Departments />} />
            <Route path="/password" element={<PasswordEncDec />} />
            <Route path="/passwordsequence" element={<GenerateSequence />} />
            {/* # changed route */}
            <Route
              path="/projectworkflow"
              element={
                <ProjectWorkflow
                  projectId={projectId}
                  handleSidebar={handleSidebar}
                />
              }
            />
            {/* # changed route */}
            <Route
              path="/projectworkflowblock"
              element={
                <ProjectWorkflowBlock
                  workflowBlockId={workflowBlockId}
                  handleSidebar={handleSidebar}
                />
              }
            />
            {/* # changed route */}
            <Route
              path="/projectdoc"
              element={<ProjectDocument projectDetails={projectDetails} />}
            />
            <Route path="/departmentdoc" element={<DepartmentDocs />} />
            <Route
              path="/stafflist"
              element={
                <UserManagement
                  sidebar={sidebar}
                  handleSidebar={handleSidebar}
                />
              }
            />
            {/* # changed route */}
            <Route
              path="/targetmanagement"
              element={<TargetManagement handleSidebar={handleSidebar} />}
            />
            <Route path="/reportingleads" element={<TeamReporting />} />
            <Route path="/assignepc" element={<AssignEPC />} />
            <Route path="/techroles" element={<TechnicalRoles />} />
            <Route path="/viewtimesheet" element={<ViewEmployee />} />
            <Route path="/reporting" element={<Reporting />} />
            <Route path="/teamreport" element={<TeamReport />} />
            <Route path="/teamreportkanban" element={<KanbanBoard />} />

            <Route
              path="/taskmanagement"
              element={
                <TaskManagement
                  setWorkflowBlockId={setWorkflowBlockId}
                  handleSidebar={handleSidebar}
                />
              }
            />
            <Route path="/list" element={<List />} />
            <Route path="/testkanban" element={<Kanban />} />
            <Route path={`/:boardname`} element={<TaskBoard />} />
            <Route path="/ticket" element={<TicketGenerate />} />
            <Route path="/resourcemapping" element={<ResourceMapping />} />
            <Route path="/resourcesheet" element={<ResourceWeekSheet />} />
            <Route
              path="/resourcespreadsheet"
              element={<ResourceSpreadsheet />}
            />
            <Route
              path="/resourcedashboard"
              element={<ResourceDashboardTable />}
            />
            <Route path="/resourcedashboardview" element={<DashboardView />} />
            <Route path="/leave" element={<LeaveManagement />} />
            <Route path="/leaveapplication" element={<LeaveApplication />} />
            <Route path="/leavestatus" element={<LeaveStatus />} />
            {
              <Route
                path="/releasenotes"
                element={<ReleaseNotes handleSidebar={handleSidebar} />}
              />
            }
            <Route
              path="/devrelease"
              element={<DevQAReleasesList sidebar={sidebar} />}
            />
            <Route
              path="/qarelease"
              element={<DevQAReleasesList sidebar={sidebar} />}
            />
          </Routes>
        </div>
      </div>
    </div>
  );
};
export default Base;
