import React, { useContext, useEffect, useMemo, useState } from "react";
import UserImagePopper from "../../popper-drawer/UserImagePopper";
import Select from "react-select";
import { UsersContext } from "../../../context/UsersContext/UsersContextProvider";
import useFinancialYearWeeks from "../../../hooks/useFinancialYearWeeks";
import { useNavigate } from "react-router-dom";
import { ResourceManagementContext } from "../../../context/ResourceManagement/ResourceManagementContextProvider";
import secureLocalStorage from "react-secure-storage";

const ResourceDashboardTable = () => {
  const [filterResourceMapping, setFilterResourceMapping] = useState([]);
  const { usersRoleWise } = useContext(UsersContext);
  const { weekOptions } = useFinancialYearWeeks();
  const navigate = useNavigate();
  const privileges = secureLocalStorage.getItem("privileges");
  const {
    resources,
    filterWith,
    isLoading,
    showWeek,
    showMonth,
    showYear,
    showUser,
    setFilterWith,
    setShowMonth,
    setShowWeek,
    setShowUser,
    setShowYear,
  } = useContext(ResourceManagementContext);
  const filterOptions = [
    { value: 0, label: "Month" },
    { value: 1, label: "Week" },
  ];
  const months = [
    { value: 1, label: "January" },
    { value: 2, label: "February" },
    { value: 3, label: "March" },
    { value: 4, label: "April" },
    { value: 5, label: "May" },
    { value: 6, label: "June" },
    { value: 7, label: "July" },
    { value: 8, label: "August" },
    { value: 9, label: "September" },
    { value: 10, label: "October" },
    { value: 11, label: "November" },
    { value: 12, label: "December" },
  ];
  const years = [
    { value: "2023", label: "2023" },
    { value: "2024", label: "2024" },
    { value: "2025", label: "2025" },
  ];
  const onChangeHandler = (event) => {
    let filterResourceMappingData;
    if (event.target.value === "") {
      filterResourceMappingData = resources;
    } else {
      filterResourceMappingData = resources.filter((item) =>
        item.employee__username.toLowerCase().includes(event.target.value)
      );
    }
    setFilterResourceMapping(filterResourceMappingData);
  };
  const handleFilters = (data) => {
    setFilterWith(data.value);
  };
  const handleWeekChange = (data) => {
    setShowWeek(data.value);
  };
  const handleMonthChange = (data) => {
    setShowMonth(data.label);
  };
  const handleYearChange = (data) => {
    setShowYear(data.value);
  };
  const handleUserChange = (data) => {
    setShowUser(data.value);
  };
  useEffect(() => {
    setFilterResourceMapping(resources);
  }, [resources]);

  return (
    <>
      <div className="row align-items-center">
        <div className="border-0 mb-4">
          <div className="card-header py-3 no-bg bg-transparent d-flex align-items-center px-0 justify-content-between border-bottom flex-wrap">
            <h5 className="fw-bold mb-0">Resource Dashboard</h5>
          </div>
        </div>
      </div>
      <div className="row align-item-center">
        <div className="col-md-12">
          <div className="card mb-3">
            <div className="card-header py-3 bg-transparent border-bottom-0">
              {/* <h6 className="mb-0 fw-bold ">Resource Mapping Table</h6> */}
              <div className="filter">
                <input
                  type="text"
                  placeholder="Search by resource..."
                  className="form-control"
                  onChange={onChangeHandler}
                />
              </div>
              {usersRoleWise.length !== 0 &&
                showUser &&
                showMonth &&
                showWeek &&
                showYear && (
                  <div className="d-flex align-items-center justify-content-end mt-3 gap-3">
                    {privileges.includes("show_resource_leads") ? (
                      <Select
                        options={usersRoleWise}
                        defaultValue={usersRoleWise.filter(
                          (u) => u.value === showUser
                        )}
                        onChange={handleUserChange}
                      />
                    ) : (
                      ""
                    )}
                    <Select
                      defaultValue={filterOptions.filter(
                        (opt) => opt.value === filterWith
                      )}
                      options={filterOptions}
                      onChange={handleFilters}
                    />
                    <Select
                      options={years}
                      defaultValue={years.filter((y) => y.value === showYear)}
                      onChange={handleYearChange}
                    />
                    {filterWith === 0 ? (
                      <>
                        <Select
                          options={months}
                          defaultValue={months.filter(
                            (m) => m.label === showMonth
                          )}
                          onChange={handleMonthChange}
                        />
                      </>
                    ) : (
                      <Select
                        options={weekOptions}
                        defaultValue={weekOptions.filter(
                          (w) => w.value === showWeek
                        )}
                        onChange={handleWeekChange}
                      />
                    )}
                  </div>
                )}
            </div>
          </div>
          {isLoading ? (
            <div className="card-body"> Loading... </div>
          ) : (
            <div className="card-body basic-custome-color">
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Employee</th>
                      <th>Total Projects</th>
                      <th>Total Hours</th>
                      <th>Total Tickets Raised</th>
                      <th>Signals Raised</th>
                      {privileges.includes("view_resource_details") ? (
                        <th>View</th>
                      ) : (
                        ""
                      )}
                    </tr>
                  </thead>
                  {filterResourceMapping.length === 0 && (
                    <tbody>
                      <tr>
                        <td></td>
                        <td>No data found</td>
                        <td></td>
                      </tr>
                    </tbody>
                  )}
                  {filterResourceMapping.length > 0 && (
                    <tbody>
                      {filterResourceMapping.map((task) => (
                        <tr key={task?.id}>
                          <td style={{ width: "25%" }}>
                            <UserImagePopper
                              id={task?.employee_id}
                              show={true}
                              margin="me-3"
                            />
                            <span style={{ fontSize: "1.3em" }}>
                              {task?.employee__username}
                            </span>
                          </td>
                          <td style={{ width: "15%" }}>
                            <span>
                              {task?.total_projects?.projects?.length || 0}
                            </span>
                          </td>
                          <td style={{ width: "15%" }}>
                            <span>{task?.total_hours}</span>
                          </td>

                          <td style={{ width: "20%" }}>
                            <span>{task?.ticket_count}</span>
                          </td>
                          <td style={{ width: "25%" }}>
                            {task?.signal_info &&
                            task?.signal_info !== 0 &&
                            task?.signal_info?.length !== 0
                              ? task?.signal_info.map((signal) => (
                                  <i
                                    class="bi bi-flag-fill text-danger"
                                    title={signal?.signal__flag_type}
                                  ></i>
                                ))
                              : "No signals raised"}
                          </td>
                          <td>
                            {privileges.includes("view_resource_details") ? (
                              <button
                                type="button"
                                className="btn btn-primary btn-sm"
                                onClick={() =>
                                  navigate("/resourcedashboardview", {
                                    state: { data: task },
                                  })
                                }
                              >
                                View
                              </button>
                            ) : (
                              ""
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  )}
                </table>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ResourceDashboardTable;
