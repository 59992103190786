import { useEffect, useRef, useState } from "react";
import useFetchBoard from "../fetch/useFetchBoard";

const useList = () => {
  const { data, isLoading, isError } = useFetchBoard();
  const [tasks, setTasks] = useState([]);
  const [activeTask, setActiveTask] = useState(null);
  const [activeKey, setActiveKey] = useState([]);
  const timeoutRef = useRef(null);

  const updateList = (task_id, position, newTask, tasksCopy) => {
    // Helper function to recursively find the task by ID
    function findTaskById(taskList, id) {
      for (let i = 0; i < taskList.length; i++) {
        const task = taskList[i];

        if (task.id === id) {
          return { task, index: i, parentList: taskList };
        }

        // Check nested tasks
        if (task.tasks && task.tasks.length > 0) {
          const nestedTask = findTaskById(task.tasks, id);
          if (nestedTask) return nestedTask;
        }
      }
      return null;
    }

    // Helper function to remove the task from its original location
    function removeTaskFromOriginalLocation(taskList, id) {
      for (let i = 0; i < taskList.length; i++) {
        const task = taskList[i];

        if (task.id === id) {
          taskList.splice(i, 1);
          return true; // Task removed
        }

        // Recursively remove from nested tasks
        if (task.tasks && task.tasks.length > 0) {
          const taskRemoved = removeTaskFromOriginalLocation(task.tasks, id);
          if (taskRemoved) return true; // Task found and removed
        }
      }
      return false;
    }

    // Remove the newTask from its original location first
    removeTaskFromOriginalLocation(tasksCopy, newTask.id);

    // Find the task by ID where we want to insert the newTask
    const taskInfo = findTaskById(tasksCopy, task_id);
    if (!taskInfo) {
      console.error(`Task with ID ${task_id} not found`);
      return tasksCopy;
    }

    const { task, index, parentList } = taskInfo;

    // Insert the new task based on the position
    switch (position) {
      case 0: // Before the task
        parentList.splice(index, 0, newTask);
        break;
      case 1: // After the task
        parentList.splice(index + 1, 0, newTask);
        break;
      case -1: // Inside the task
        if (!task.tasks) {
          task.tasks = [];
        }
        task.tasks.push(newTask); // Add new task to the nested tasks
        break;
      default:
        console.error("Invalid position");
        return tasksCopy;
    }

    return tasksCopy; // Return the updated list of tasks
  };

  const toggleAccordions = (key) => {
    if (activeKey.includes(key)) {
      setActiveKey((prev) => prev.filter((ac) => ac !== key));
    } else {
      setActiveKey((prev) => [...prev, key]);
    }
  };

  const openAccordion = (key) => {
    setActiveKey((prev) => [...prev, key]);
  };

  const handleDragStart = (event) => {
    const task = event.active.data.current?.item;
    if (task) {
      setActiveTask(task);
      setActiveKey((prev) => prev.filter((t) => t !== task?.id));
    }
  };

  const handleDragOver = (event) => {
    if (
      event.active.data.current?.item?.id !== event.over.data.current?.item?.id
    ) {
      // Clear any existing timeout to debounce the call
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      // Set a new timeout to open the accordion after 1 second
      timeoutRef.current = setTimeout(() => {
        openAccordion(event.over?.id);
      }, 1000);
    }
  };

  const handleDragEnd = (event) => {
    console.log(event);
    if (!event.over || typeof event.over.id !== "string") {
      return;
    }
    const task_id = parseInt(event.over?.id.split("_")[0]);
    if (task_id === activeTask?.id) {
      return;
    }
    const position = parseInt(event.over?.id.split("_")[1]);
    const copyOfTasks = JSON.parse(JSON.stringify(tasks));
    const new_list = updateList(
      task_id,
      position,
      event.active.data.current?.item,
      copyOfTasks
    );
    console.log(new_list);
    setTasks(new_list);
  };

  useEffect(() => {
    if (data && !isLoading && !isError) {
      setTasks(data?.data?.tasks);
    }
  }, [data, isLoading, isError]);

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  return {
    tasks,
    setTasks,
    handleDragStart,
    handleDragOver,
    handleDragEnd,
    toggleAccordions,
    openAccordion,
    activeKey,
    activeTask,
  };
};

export default useList;
