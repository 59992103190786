import { useDroppable } from "@dnd-kit/core";
import React from "react";

const DragOverDetector = ({ children, id }) => {
  const { setNodeRef } = useDroppable({
    id: id,
  });
  return <div ref={setNodeRef}>{children}</div>;
};

export default DragOverDetector;
