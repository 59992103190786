import { useQuery } from "react-query";
import { fetchCurrentBoard } from "../../api/endpoints/task-management/currentBoard";

const useFetchCurrentBoard = (id) => {
  return useQuery(["current-board", id], () => fetchCurrentBoard(id), {
    enabled: !!id,
    staleTime: Infinity, // Data will never go stale
    cacheTime: Infinity, // Data will be cached indefinitely
    refetchOnWindowFocus: false, // Do not refetch when window is focused
    refetchOnMount: false, // Do not refetch when component mounts
    refetchOnReconnect: false, // Do not refetch when reconnecting to the internet
  });
};

export default useFetchCurrentBoard;
